import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { useTokens, useSessionStore, useBookmarks } from '../SamState';
import { useFetchApi } from '../useDataApiV2';
import usePopups from '../usePopups';
import useAddressBook from '../useAddressBook';
import ShoppingCartList from './ShoppingCartListV2';

import { WSOrderRecord, WSShoppingCartRecord, WSCouponRecord, WSCategoriesRecord, WSCategoryRecord } from '../../interfaces/ws-api-interfaces';
import { AddressRecord, AddressType, CreditCardSummaryRecord, TokenRecord } from '../../interfaces/lib-api-interfaces';
import { ShoppingCartListRecord } from '../../interfaces/lib-react-interfaces';

import '../../App.css';
import app from '../../appData';
import api from '../../api-url';

// this contains support code to manipulate shopping cart and order info; no rendering
// this could be made into a lib by adding callbacks for functions that require knowledge of skus, etc.
// NOTE: CURRENTLY THIS IS ONLY USED BY JACOBSCHIMES.COM

/* raw data is in session store, loaded when customer logs in:
    order contains: (initialized by api if no pending order existed)
        shipTos contains array of all ship-to addresses on file (could be empty) in case cust wants to choose one
            { company,address1,address2,city,state,zip,country,attn,phone,email }
        background fields: (order_id should always be 0 as it is assigned upon import into belmont server)
            cust_id, order_id, status, create_time,	mod_time, req_date,	terms, net_tot, comment, coupon_code
        bill and ship address (all bill fields prefixed with "bill_" and ship with "ship_")
            company, attn, address1, address2, city, state,	zip, country
        bill only:
            name, email, phone
        shipping info:
            carrier, ship_via, frt_pay_method, frtcollect
        credit card info:
            cc_plain, cc, card_type, last4
        shoppingCart is array of items in cart:
            image, caption, item_no, qty, price, ext (price and ext calculated on client side)
            image is formatted on the fly as follows: "https://jacobschimes.com/graphics/orderform/ITEM.gif" where ITEM is item # without dash (e.g. "VANH" or "DBUG")
    categories is an object containing one object for each category (e.g.. "Car Charm") -- qty and ext are calculated by client
        {category : {record}
        where category is the 1 char category designator (e.g. "V" for Car Charms) and record is the following:
            long_desc, price, discounted_price, display_item_no, display_free_qty, display_order, qty, ext
*/

/*
export interface WSOrderRecord {
    order_id: number;
    cust_id: number;
    status: string;
    create_time: string;
    req_date: string;
    terms?: string;
    mod_time?: string;
    bill_contact_name?: string;
    bill_name?: string;
    bill_company?: string;
    bill_address1?: string;
    bill_address2?: string;
    bill_city?: string;
    bill_state?: string;
    bill_zip?: string;
    bill_country?: string;
    bill_attn?: string;
    bill_phone?: string;
    bill_email?: string;
    carrier?: string;
    frtCollect?: boolean;
    shipto_id?: number;
    ship_via?: string;
    frt_pay_method?: string;
    card_type?: string;
    cc: string;
    last4?: string;      // if last4 is returned to client with empty cc it means cust is using credit card on file
    shipTos: AddressRecord[];
    shoppingCart: WSOrderRecord;
    net_tot: number;
}
export interface WSShoppingCartRecord extends Record<string, any> {
    order_id?: number;      // not held in memory
    main_image: string;
    caption: string;
    item_no: string;
    qty: number;
}
export interface WSCategoriesRecord {
      [key: string]: WSCategoryRecord;
  }
export interface WSCategoryRecord {
    division: string;
    category: string;
    long_desc: string;
    short_desc: string;
    banner_image: string;
    product_copy: string;
    bullet_points: string;
    prepack_desc: string;
    prepack_price: number;
    price: number;
    discounted_price: number;
    display_item_no: string;
    display_free_qty: number;
    display_order: number;
    ferns_garden_url: string;
    thumb_width: number;
    thumb_height: number;
    image_width: number;
    image_height: number;
    qty: number;
    ext: number;
}

*/

const display = 'D';        // all display item numbers start with D
const discountQty = 36;     // always discount when 36 or more per category is ordered
const cartSuppressedLabel = "cartSuppressed";
export const noCartPopupWidth = 660;

export enum OrderTerms { creditCard = "V", callForCard = "C", proForma = "P", net30 = "N", other = "?" }
export enum OrderStatus { working = 'W', pendingDownload = 'P', downloading = 'D' };

//-------- For CartIcon ------------
const StyledCartIconContainer = styled.div`
position: relative;
`
const StyledCartIcon = styled.div`
font-size: 34px;
`
const StyledCartItemCounter = styled.span`
color: white;
position: absolute;
top: 40%;
left: 55%;
transform: translate(-50%, -50%);
font-size: 12px;
font-weight: bold;
cursor: pointer;
`
const StyledPopup = styled.p<{ $backColor: string }>`
background-color: ${props => props.$backColor};
padding: 16px;
border: 1px solid;
`
const TotalsRow = styled.div`
display: flex;
justify-content: center;
height: 36px;
align-items: center;
`
const TotalsLabel = styled.span`
font-weight: bold;
margin-right: 8px;
`

const categoriesKey = "categories";
const orderKey = "order";

const useShoppingCart = () => {
    const { setSessionStore, getSessionStore, sessionStoreContains } = useSessionStore();
    const addressBook = useAddressBook();

    //------------------- GETTERS AND SETTERS -------------------------------------------
    const getOrder = (): WSOrderRecord => {
        return getSessionStore(orderKey);
    }
    const getCategories = (): WSCategoriesRecord => {
        return getSessionStore(categoriesKey);
    }
    const categoriesExist = (): boolean => {
        return sessionStoreContains(categoriesKey);
    }
    // convert category url into category code
    const getCategoryFromUrl = (url: string): string => {
        const categories = getCategories();
        if (!categories) {
            return '';
        }
        for (const category in categories) {
            if (categories[category].url === url) {
                return category;
            }
        }
        return '';
    }
    const setOrder = (order: WSOrderRecord) => {
        setSessionStore(orderKey, order);
    }
    const setCategories = (categories: WSCategoriesRecord) => {
        setSessionStore(categoriesKey, categories);
    }
    const setCategoriesFromArray = (records: WSCategoryRecord[]): WSCategoriesRecord => {
        // combine multiple category records into a single category object so we can access like dictionary
        const categories: WSCategoriesRecord = {};
        records.forEach(categoryRecord => {
            categories[categoryRecord.category] = categoryRecord;
        });
        setCategories(categories);
        return categories;
    }
    const setOrderTerms = (terms: OrderTerms) => {
        const order = getOrder();
        order.terms = terms;
        setOrder(order);
    }
    const setOrderShipAddress = (addressId: number) => {
        const order = getOrder();
        order.shipto_id = addressId;
        setOrder(order);
    }
    const setOrderShipDate = (date: string) => {
        const order = getOrder();
        order.req_date = date;
        setOrder(order);
    }
    const setCCSummary = (values: CreditCardSummaryRecord) => {
        const order = getOrder();
        order.cc_summary = values;
        setOrder(order);
    }
    const itemCount = (): number => {
        const categories = getCategories();
        let count = 0;
        for (const category in categories) {
            if (categories[category].qty) {
                count += categories[category].qty;
            }
        }
        return count;
    }
    const getShipAddress = (): AddressRecord => {
        const order = getOrder();
        const id = order.shipto_id;
        if (id) {
            const addr = addressBook.getAddress(AddressType.ship, id);
            if (!addr) {
                setOrderShipAddress(0);
                return order.bill_address;
            } else {
                return addr;
            }
        } else {
            return order.bill_address;
        }
    }
    const isCartSuppressed = (): boolean => {
        return getSessionStore(cartSuppressedLabel);
    }

    //------------------- RECALC ROWS AND TOTALS -------------------------------------------
    // cart, categories and order are updated
    // updated data is saved to session state
    const recalcCart = (order: WSOrderRecord, categories: WSCategoriesRecord) => {
        // zero out category qty and ext, then make a pass for qty only, then a pass to assign prices based on qty in each category
        for (const category in categories) {
            categories[category].qty = 0;
            categories[category].ext = 0;
        };
        //      console.log("setting shoppingCart for " + order.shoppingCart.length + " items");
        // pass to count qty only
        //  console.log("recalcCart: shoppingCart:", order.shoppingCart)
        order.shoppingCart.forEach(item => {
            const itemCategory = item.item_no[0] === 'Y' ? item.item_no[1] : item.item_no[0];
            //           console.log("category=" + itemCategory);
            if (itemCategory !== display) {
                categories[itemCategory].qty += (item.item_no[0] === 'Y' ? item.qty * categories[itemCategory].display_free_qty : item.qty);
            }
        });
        // pass to adjust price and calculate totals
        //      console.log("setting categories");
        order.net_tot = 0;
        order.shoppingCart.forEach(item => {
            if (item.is_freebie) {
                item.price = 0;
            } else {
                const itemCategory = item.item_no[0] === 'Y' ? item.item_no[1] : item.item_no[0];
                // displays are either free or $5
                if (itemCategory === display) {
                    item.price = categories[itemCategory].qty >= categories[itemCategory].display_free_qty ? 0 : 5;
                } else if (item.item_no[0] === 'Y') {
                    item.price = categories[itemCategory].prepack_price;
                } else {
                    item.price = categories[itemCategory].qty >= discountQty ? categories[itemCategory].discounted_price : categories[itemCategory].price;
                }
                item.ext = item.price * item.qty;
                categories[itemCategory].ext += item.ext;
                order.net_tot += item.ext;
            }
        });
        //      console.log("storing categories");
        setCategories(categories);
        //       console.log("storing order");
        setOrder(order);
        //      console.log("returning from recalcCart; order:", order);
    }
    //------------------- END RECALC ROWS AND TOTALS -------------------------------------------
    // return cart item object or null
    const getItem = (shoppingCart: WSShoppingCartRecord[], itemNo: string) => {
        return shoppingCart.find(item => item.item_no === itemNo);
    }
    // no dash in itemNo
    const addToCart = (itemNo: string, qty: number, caption: string, mainImage: string, isFreebie: boolean = false) => {
        const order = getOrder();
        const existing = getItem(order.shoppingCart, itemNo);
        if (existing) {
            existing.qty += qty;
        } else {
            order.shoppingCart.push({ main_image: mainImage, caption: caption, item_no: itemNo, qty, price: 0, ext: 0, is_freebie: isFreebie });   // price and ext are set at recalcCart
        }
        recalcCart(order, getCategories());
    }
    // itemNo is item to be removed, order.shoppingCart in state is updated
    const removeItem = (itemNo: string) => {
        let cart: WSShoppingCartRecord[] = [];
        const order = getOrder();
        order.shoppingCart.forEach(item => {
            if (item.item_no !== itemNo) {
                cart.push(item);
            }
        });
        recalcCart({ ...order, shoppingCart: cart }, getCategories());
    }
    const updateQty = (qty: number, itemNo: string) => {
        const order = getOrder();
        const item = getItem(order.shoppingCart, itemNo);
        if (item) {
            item.qty = qty;
        }
        recalcCart(order, getCategories());
    }
    const applyCoupon = (coupon: WSCouponRecord) => {
        const order = getOrder();
        order.coupon_code = coupon.coupon;
        if (coupon.free_item_no) {
            addToCart(coupon.free_item_no, 1, coupon.free_item_caption, coupon.free_item_caption, true);
        } else if (coupon.discount) {
            order.disc_pct = coupon.discount;
            order.disc_cmnt = coupon.comment;
        }
        setOrder(order);
    }
    // called after order is submitted -- prevents call to server to reload order that has just been submitted
    const setOrderSubmitted = () => {
        const order = getOrder();
        order.status = OrderStatus.pendingDownload;
        order.shoppingCart = [];
        order.mod_time = dayjs().format();
        setOrder(order);
    }
    // return true if order was modified less than a day ago and is set to status of pending download (after a day we can re-check with server to update status)
    const isOrderPendingDownload = (order: WSOrderRecord) => {
        return (order.status === OrderStatus.pendingDownload && dayjs(order.mod_time) > dayjs().add(-2, "day"));
    }

    /* CartIcon acts as controller for shopping cart panel on right (CartPopup, below):
    Displays icon in header if user is logged in
    Handles mouse hover over cart icon: displays cart panel if appropriate and not already showing
    */
    const CartIcon: React.FC = () => {
        const [updateOrderStatus, setUpdateOrderStatus] = React.useState(false);
        const [orderStatusChecked, setOrderStatusChecked] = React.useState(false);

        const { fetch, isFetchLoading } = useFetchApi();
        const { getToken } = useTokens();
        const { renderPopup, invokePopup } = usePopups();

        const cart = useShoppingCart();
        const navigate = useNavigate();


        const cartIcon = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

        enum MessageCode { empty = 'e' };        // for message object in state
        const messageWidth = 320;

        React.useEffect(() => {
            // following can only be called from useEffect
            if (updateOrderStatus && !isFetchLoading()) {
                const token = getToken() as TokenRecord;
                const url = api.fetchOrder + "/" + token.userId;
                fetch(url, token.token, data => {
                    setOrder(data);
                    setOrderStatusChecked(true);
                    setUpdateOrderStatus(false);
                }, () => { });
            }
        });
        const handleCartClick = () => {
            if (window.matchMedia("(max-width: " + noCartPopupWidth + "px)").matches) {
                navigate("/checkout");
            } else {
                setSessionStore(cartSuppressedLabel, false);
                window.location.reload();
            }
        }

        const showMessage = (code: MessageCode) => {
            const elem = cartIcon.current;
            if (elem) {
                const rect = elem.getBoundingClientRect();
                const top = Math.max(rect.top + 36, window.scrollY);
                let left = rect.left + rect.width - messageWidth / 2;
                if (left + messageWidth + 32 > window.innerWidth) {
                    left = window.innerWidth - messageWidth - 32;
                }
                //       setPopupInfo({ messageCode, top, left });      // add mouse move rect after message is rendered
                const Popup: React.FC = () => {
                    if (code === MessageCode.empty) {
                        return (
                            <StyledPopup $backColor={app.themes.backColor10}>
                                Your cart is empty
                            </StyledPopup>
                        )
                    } else {
                        return null;
                    }
                }
                invokePopup({ Popup, width: messageWidth, left, top });
            }
        }
        // mouseOver cart icon: show "empty" message if empty, display cart panel if not empty
        const handleCartMouseOver = () => {
            const order = getOrder();
            if (order) {
                if (order.shoppingCart.length === 0) {
                    showMessage(MessageCode.empty);
                }
            }
        }

        let cartItemIndicator = '';
        const order = getOrder();
        const categories = getCategories();
        if (order && categories) {
            if (order.shoppingCart && order.shoppingCart.length > 0) {
                cartItemIndicator = cart.itemCount() + '';
            } else if (cart.isOrderPendingDownload(order)) {
                cartItemIndicator = "X";
            }
        }
        return (
            isFetchLoading(api.fetchOrder + "/" + getToken()!.userId) ? (
                <i className="fa fa-spinner fa-spin spinner48"></i>
            ) : (
                <StyledCartIconContainer>
                    {cartItemIndicator &&
                        <StyledCartItemCounter onClick={handleCartClick}>{cartItemIndicator}</StyledCartItemCounter>}
                    <StyledCartIcon ref={cartIcon} onMouseOver={handleCartMouseOver} onClick={handleCartClick}>
                        <i className="fas fa-shopping-cart" />
                    </StyledCartIcon>
                    {renderPopup()}
                </StyledCartIconContainer>
            )
        )
    }

    interface CartPopupProps {
        totalChanged: () => void;
    }
    const CartPopup: React.FC<CartPopupProps> = (props) => {
        const cart = useShoppingCart();
        const navigate = useNavigate();
        const { setBookmark, navigateToBookmark } = useBookmarks();

        const qtyChanged = (itemNo: string, qty: number) => {
            cart.updateQty(qty, itemNo);
            props.totalChanged();
        }
        const handleRemove = (itemNo: string) => {
            cart.removeItem(itemNo);
            props.totalChanged();
        }
        const handleCheckout = () => {
            setBookmark(window.location.href);
            navigate('/checkout');
        }
        const cartListCloseClicked = () => {
            setSessionStore(cartSuppressedLabel, true);
            navigateToBookmark();
        }
        const items: ShoppingCartListRecord[] = [];
        cart.getOrder().shoppingCart.forEach(item => {
            items.push({ caption: item.caption, id: item.item_no, imageFilename: item.main_image, qty: item.qty, price: item.price });
        });
        const OrderTotals: React.FC = () => {
            const order = getOrder();
            return (
                <TotalsRow>
                    <TotalsLabel>Total:</TotalsLabel>
                    <span>${order.net_tot.toFixed(2)}</span>
                </TotalsRow>
            )
        }
        if (items.length && !items[0].price) {
            return null;
        }
        return (
            <ShoppingCartList items={items} positionAbsolute={false} renderTotals={OrderTotals}
                checkoutClicked={window.location.href.endsWith("/checkout") ? undefined : handleCheckout}
                cartItemQtyChanged={qtyChanged} cartItemRemoved={handleRemove} closeClicked={cartListCloseClicked} />
        )
    }
    /*
     invTot={cart.getOrder().net_tot}
    totalChanged={props.totalChanged} 
    
        items: ShoppingCartListRecord[];
        positionAbsolute?: boolean;     // if true the popup is positioned to right side of screen and a "close" button is added
        scrollToTop?: boolean;          // for mobile
        cartItemQtyChanged: (id: string, qty: number) => void;
        cartItemRemoved: (id: string) => void;
        // following 3 buttons are displayed if handlers are passed in
        checkoutClicked?: VoidCallback;      // if passed, checkout button is shown
        closeClicked?: VoidCallback;      // required if positionAbsolute is true; if not true the close button is shown
        saveOrderClicked?: VoidCallback;
        // following required if saveOrderClicked passed in
        renderSaveOrderStatus?: React.ComponentType<any>;
        // following called at bottom of list to show totals
        renderTotals?: React.ComponentType<any>;
    */
    return {
        OrderStatus,

        getOrder,
        setOrder,
        categoriesExist,
        getCategories,
        setCategoriesFromArray,
        getCategoryFromUrl,
        setCategories,
        setOrderTerms,
        setOrderShipAddress,
        setOrderShipDate,
        setCCSummary,
        itemCount,
        getShipAddress,
        recalcCart,
        addToCart,
        removeItem,
        updateQty,
        applyCoupon,
        setOrderSubmitted,
        isOrderPendingDownload,
        isCartSuppressed,

        CartPopup,
        CartIcon
    };
}
export default useShoppingCart;
