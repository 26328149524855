import React from 'react';
import styled from 'styled-components';

import { getNearestParentId } from '../libSupport';

const PopupComboInputAndChevron = styled.div<{ $width: number }>`
    display: flex;
    justify-content: space-between;
    height: 30px;
    position: relative;
    border: 1px solid;
    margin-right: 4px;
    width: ${props => props.$width + 20}px;
    input {
        width: ${props => props.$width}px;
        border: none;
    }
`
const PopupChevron = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
`
interface PopupComboProps {
    id: string;
    comboList?: string[];       // not needed if parent is handling onPopupOpened so list never shown
    width: number;
    listIsFontSizes?: boolean;
    popupFontSize?: number;
    value: string;
    //   inputRefCallback: (inputRef: React.MutableRefObject<HTMLInputElement>) => void;
    onPopupOpened?: () => void;
    onChange?: (id: string, value: string) => void;
}
const PopupCombo: React.FC<PopupComboProps> = (props) => {
    const [showPopup, setShowPopup] = React.useState(false);
    const [inputHeight, setInputHeight] = React.useState(0);
    const [popupMaxHeight, setPopupMaxHeight] = React.useState(0);
    const [closePopup, setClosePopup] = React.useState(false);

    const inputRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const popupFontSize = props.popupFontSize ?? 18;

    React.useEffect(() => {
        const rect = inputRef.current.getBoundingClientRect();
        setPopupMaxHeight(window.innerHeight - rect.height - rect.top);
        setInputHeight(rect.height);
        //     props.inputRefCallback(inputRef);
    }, []);
    React.useEffect(() => {
        if (closePopup) {
            setShowPopup(false);
            setClosePopup(false);
        }
    }, [closePopup]);

    const chevronClicked = () => {
        setShowPopup(true);
        props.onPopupOpened && props.onPopupOpened();
    }
    const inputValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setClosePopup(true);
        props.onChange && props.onChange(props.id, e.target.value);
    }
    const inputKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        setShowPopup(true);
    }
    const popupClicked = (value: string) => {
        setClosePopup(true);
        props.onChange && props.onChange(props.id, value);
    }
    return (
        <PopupComboInputAndChevron $width={props.width} onMouseLeave={() => setShowPopup(false)}>
            <input ref={inputRef} type="text" value={props.value} onKeyPress={inputKeyPressed} onChange={inputValueChanged} />
            <PopupChevron>
                <i onClick={chevronClicked} className="bi bi-caret-down-fill" />
            </PopupChevron>

            {showPopup && props.comboList && <PopupComboPopup list={props.comboList} listIsFontSizes={props.listIsFontSizes} fontSize={popupFontSize} 
                onCancel={() => setShowPopup(false)}
                top={inputHeight} left={4} width={props.width + 20} maxHeight={popupMaxHeight - 20}
                onClick={popupClicked}/>}
        </PopupComboInputAndChevron>
    )
}

//--------------------------------------------------------------------------------------
const MainContainer = styled.div<{ $top: number; $left: number; $width: number; $maxHeight: number; $fontSize: number }>`
    position: absolute;
    top: ${props => props.$top}px;
    left: ${props => props.$left}px;
    width: ${props => props.$width}px;
    max-height: ${props => props.$maxHeight}px;
    font-size: ${props => props.$fontSize}px;
    overflow-y: auto;
`
const PopupContainer = styled.div`
    background-color: tan;
    display: flex;
    flex-direction: column;
    div:hover {
        background-color: yellow;
    }
`
const PopupEntry = styled.div<{ $fontSize: number }>`
    cursor: pointer;
    font-size: ${props => props.$fontSize}px;
    p {
        margin: 4px 8px 4px 8px;
      }
`
interface PopupComboPopupProps {
    list: string[];
    fontSize: number;
    listIsFontSizes?: boolean;
    top: number;
    left: number;
    maxHeight: number;
    width: number;
    onClick: (value: string) => void;
    onCancel: () => void;
}
const PopupComboPopup: React.FC<PopupComboPopupProps> = (props) => {
    const entryClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = getNearestParentId(e.target as HTMLElement);
        props.onClick(target.id);
    }

    return (
        <MainContainer $top={props.top} $left={props.left} $width={props.width} $maxHeight={props.maxHeight} $fontSize={props.fontSize}>
            <PopupContainer>
                {props.list.map(entry => {
                    return (
                        <PopupEntry id={entry} key={entry} 
                            $fontSize={props.listIsFontSizes ? parseInt(entry) : props.fontSize}
                            onClick={entryClicked}>
                            <p>{entry}</p>
                        </PopupEntry>
                    )
                })}
            </PopupContainer>
        </MainContainer>
    )
}
export default PopupCombo;