import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

import MasterPage from '../libs/MasterPage';
import { useBookmarks, useTokens } from '../libs/SamState';
import { formatImageUrl } from '../libs/ImageFormatter';
import { Divider } from '../libs/Contact';

import app from '../appData';

const StyledContainer = styled.div`
    max-width: 500px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    p {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        color: black;
        margin-top: 0;
        margin-bottom: 0;
    }
    h2 {
        font-size: 16px;
    }
    h3 {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: bold;
    }
`
const LoginLink = styled.span`
    text-decoration: underline;
    cursor: pointer;
`
const Retailers: React.FC = () => {
    const { setBookmark } = useBookmarks();
    const { getToken } = useTokens();
    const navigate = useNavigate();

    const handleLogin = () => {
        setBookmark();
        navigate("/login");
        return false;
    }

    return (
        <MasterPage>
            <h1>Become a Retailer of Jacob's Musical Chimes</h1>
            <StyledContainer>
                <h2>If you own a retail store, it is easy to order from Jacob's Musical Chimes!</h2>
                <Divider src={formatImageUrl(app.twigDividerImage)} />
                <h3>Call, fax or email your order</h3>
                <p>Phone (800) 627-5840</p>
                <p>Fax (510) 529-4833</p>
                <p>Email <a href="mailto:orders@jacobschimes.com">orders@jacobschimes.com</a></p>
                <Divider src={formatImageUrl(app.twigDividerImage)} />
                <h3>Order online</h3>
                {getToken() ? (
                    <span>As you browse products, click the &quot;Add to cart&quot; button</span>
                ) : (
                    <LoginLink onClick={handleLogin}>Click here to sign in or register and create a shopping cart</LoginLink>
                )}
                <div>
                    <Divider src={formatImageUrl(app.twigDividerImage)} />
                    <h3>Find a rep</h3>
                </div>
                <p><Link to="/reps">Click here to view our current rep list.</Link></p>
                <Divider src={formatImageUrl(app.twigDividerImage)} />
                <h3>Jacob's Musical Chimes is now on Faire!</h3>
                <p>If you are not yet a Faire buyer, you may qualify for a $100 credit, free freight and Net 60 terms.
                    <a href="https://jacobsmusicalchimes.faire.com"> Visit our Faire store to find out!</a></p>
                <h3>View our catalog</h3>
                <p><Link to="/catalog">Click here to view a PDF Jacob's Musical Chimes catalog</Link></p>
            </StyledContainer>
        </MasterPage>
    );
}

export default Retailers;


