import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { usePostApi } from '../libs/useDataApiV2';
import { StyledErrorText } from '../libs/libSupport';
import SamForm from '../libs/forms/SamFormV5';
import MasterPage from '../libs/MasterPage';
import '../App.css';
import { useTokens, useBookmarks, useSessionStore, useCookies, useGlobalContext } from '../libs/SamState';
import useShoppingCart from '../libs/shopping-cart/useShoppingCart';
import useAddressBook from '../libs/useAddressBook';

import { ApiWSRegisterRecord, WSLoginResponseRecord } from '../interfaces/ws-api-interfaces';
import { LoginError, FormFieldType, FormFieldRecord, AddressType } from '../interfaces/lib-api-interfaces';

import app from '../appData';
import api from '../api-url';
import FormMgr from '../libs/forms/FormMgr';

const StyledContainer = styled.div`
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    p {
        font-size: 14px;
        line-height: 22px;
        color: black;
        margin-top: 0;
        margin-bottom: 0;
        text-align: left;
    }
    h2 {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: bold;
    }
    a:link {
        text-decoration: underline !important;
    }
`
const FormDiv = styled.div`
`

const Register: React.FC = () => {
    const { post, isPostLoading } = usePostApi();
    const { setToken } = useTokens();
    const { getSessionStore } = useSessionStore();
    const { setCookie } = useCookies();
    const cart = useShoppingCart();
    const { navigateToBookmark } = useBookmarks();
    const forms = new FormMgr(useGlobalContext().setContext);
    const addressBook = useAddressBook();

    const [errorMsg, setErrorMsg] = React.useState<string>();

    const successfulPost = (loginResult: WSLoginResponseRecord, status: LoginError | undefined) => {
        if (status === LoginError.custNotFound) {
            setErrorMsg("We could not find you in our records. Please call us at 800-627-5840 to confirm the customer number and zip we have for you.");
        } else {
            const persist = forms.getValue("login", "remember") as boolean;
            setToken(loginResult.token, persist);
            setCookie("company", loginResult.company, 30);
            addressBook.setAddresses(AddressType.ship, loginResult.shipTos);
            if (app.shoppingCart) {
                cart.recalcCart(loginResult.order, getSessionStore("categories"));
            }
            navigateToBookmark();
        }
    }
    const failedPost = (message: string) => {
        setErrorMsg(message);
    }

    const handleSubmit = (inputs: Record<string, any> | null, id: string | null) => {
        if (inputs) {
            const values = inputs as ApiWSRegisterRecord;
            values.division = app.division;
            post(api.register, inputs, successfulPost, failedPost);
        }
    }

    // if condition is set, that condition must be set to true via setCondition or field will not be rendered
    const formData: FormFieldRecord[] = [
        { name: "cust_id", type: FormFieldType.digitsOnly, label: "Enter your customer number", width: 48, validator: { required: true, maxLength: 5 } },
        { name: "zip", type: FormFieldType.digitsOnly, label: "Enter your billing zip code", width: 48, validator: { required: true, maxLength: 5 } },
        { name: "email", label: "Enter your email to log in with", width: 48, validator: { required: true, maxLength: 100 } },
        { name: "password", label: "Create your password", width: 48, type: FormFieldType.text, validator: { isPassword: true, required: true, maxLength: 16 } },
        { name: "email_not", type: FormFieldType.checkbox, label: "Do not email me with offers and discounts" },
        { name: "remember", type: FormFieldType.checkbox, label: "Remember me on this device" }
    ];
    return (
        <MasterPage>
            <h1>Register as a Jacob's Chimes Customer</h1>
            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
            <StyledContainer>
                <p>Your customer number is a 4 or 5 digit number, found on your latest invoice or packing slip. If you don't know it, please call us at 800-627-5840
                    or drop us an email at info@jacobschimes.com and we'll be happy to look it up for you.
                </p>
                <p>If you registered before, please <Link to="/login">click here to sign in.</Link> We recently upgraded our site and if you registered with a user name (not email)
                    you will need to re-register.</p>
                {isPostLoading(api.register) && <i className="fa fa-spinner fa-spin spinner48"></i>}
                <FormDiv>
                    <SamForm id="register" fields={formData} submitButtons={[{ id: "submit", caption: "Register", icon: "fas fa-check", validate: true }]}
                        handleSubmit={handleSubmit} />
                </FormDiv>
            </StyledContainer>
        </MasterPage>
    );
}
export default Register;