import { AddressRecord } from '../../interfaces/lib-api-interfaces';

export enum AddressFieldType { string = 's', number = 'n', bool = 'b' }
class AddressMap {
    field: string;
    type: AddressFieldType;
    constructor(field: string, type: AddressFieldType) {
        this.field = field;
        this.type = type;
    }
}
const map: AddressMap[] = [
    new AddressMap("fname", AddressFieldType.string),
    new AddressMap("lname", AddressFieldType.string),
    new AddressMap("contact_name", AddressFieldType.string),
    new AddressMap("company", AddressFieldType.string),
    new AddressMap("attn", AddressFieldType.string),
    new AddressMap("address1", AddressFieldType.string),
    new AddressMap("address2", AddressFieldType.string),
    new AddressMap("city", AddressFieldType.string),
    new AddressMap("state", AddressFieldType.string),
    new AddressMap("province", AddressFieldType.string),
    new AddressMap("zip", AddressFieldType.string),
    new AddressMap("phone", AddressFieldType.string),
    new AddressMap("country", AddressFieldType.string),
    new AddressMap("email", AddressFieldType.string),
    new AddressMap("address_id", AddressFieldType.number),
    new AddressMap("storefront", AddressFieldType.bool),
    new AddressMap("res_del", AddressFieldType.bool),
    new AddressMap("is_default", AddressFieldType.bool),
];
// take any object, find its address fields and extract into a new AddressRecord object
// prefix applies to the passed in object; the AddressRecord object is never prefixed
// this version only copies existing fields, but does init them to non-null
const collect = (record: Record<string, string | boolean | number>, prefix?: string): AddressRecord => {
    const p: string = prefix ? prefix : '';
    const addr = {} as AddressRecord;
    for (const field of map) {
        if ((p + field.field) in record) {
            let val: string | boolean | number = record[p + field.field];
            if (!val) {
                if (field.type === AddressFieldType.bool) {
                    val = false;
                } else if (field.type === AddressFieldType.number) {
                    val = 0;
                } else {
                    val = '';
                }
            }
            addr[field.field] = val;
        }
    };
    return addr;
}
// take an AddressRecord object and copy it into a target object
// all fields in source are added to target; fields in target will be prefixed (source has no prefix)
const scatter = (source: AddressRecord, target: Record<string, any>, prefix?: string) => {
    const p: string = prefix ? prefix : '';
    for (const field in source) {
        target[p + field] = source[field];
    }
}
// return null if field not found
const getFieldType = (field: string): AddressFieldType | null => {
    const record = map.find(mapField => mapField.field === field);
    if (record) {
        return record.type;
    }
    return null;
}
const toArray = (record: AddressRecord): string[] => {
    //    console.log("toArray: values=", this)
    let address = [];
    if (record.fname || record.lname) {
        address.push((record.fname ? record.fname : '') + " " + (record.lname ? record.lname : ''));
    }
    if (record.company) {
        address.push(record.company);
    }
    if (record.attn) {
        address.push("ATTN:" + record.attn);
    }
    if (record.address1) {
        address.push(record.address1);
    }
    if (record.address2) {
        address.push(record.address2);
    }
    const cityStateZip = _formatCityStateProvinceZip(record);
    if (cityStateZip) {
        address.push(cityStateZip);
    }
    if (isNotUSA(record)) {
        address.push(record.country);
    }
    //   console.log("toArray returning", address)
    return address;
}
const _formatCityStateProvinceZip = (record: AddressRecord): string => {
    const city = record.city ? record.city : '';
    const state = record.state ? record.state : '';
    const province = record.province ? record.province : '';
    const zip = record.zip ? record.zip : '';
    const stateProvince = (state + province).trim();
    return (city + (stateProvince ? ", " : '') + stateProvince + ' ' + zip).trim();
}
const toHtml = (record: AddressRecord): string => {
    const array = toArray(record);
    let html = '';
    for (const text of array) {
        html += text + "<br />";
    }
    return html;
}
const isNotUSA = (record: AddressRecord): boolean => {
 //   console.log("isNotUSA:", record)
    if (record.zip && isNaN(parseInt(record.zip.replace('-', '')))) {
        return true;
    }
    let country = record.country;
    if (!country) {
        return false;
    }
    country = country.toLowerCase().trim();
    return (country !== "us" && country !== "usa" && country !== "united states");
}
// ignore non string fields (address_id, is_default, etc)
const isEmpty = (record: AddressRecord): boolean => {
    return !toSingleString(record);
}
// ignore non string fields (address_id, is_default, etc)
const equals = (record1: AddressRecord, record2: AddressRecord): boolean => {
    return toSingleString(record1).toLowerCase() === toSingleString(record2).toLowerCase();
}
// ignore non string fields (address_id, is_default, etc)
const toSingleString = (record: AddressRecord): string => {
    let text = '';
    map.forEach(field => {
        if (field.type === AddressFieldType.string && field.field in record) {
            text += (record[field.field] ? record[field.field].trim() : '');
        }
    });
    return text;
}
const toFormattedString = (record: AddressRecord): string => {
    const name = (record.fname ? record.fname : '') + ' ' + (record.lname ? record.lname : '');
    const company = record.company ? record.company : '';
    const address1 = record.address1 ? record.address1 : '';
    const address2 = record.address2 ? record.address2 : '';
    const city = record.city ? record.city : '';
    const state = record.state ? record.state : '';
    const province = record.province ? record.province : '';
    const zip = record.zip ? record.zip : '';
    const country = record.country && isNotUSA(record) ? record.country : '';
    return addComma(name) + addComma(company) + addComma(address1) + addComma(address2) + addComma(city) + ' ' + state + ' ' + province + ' ' + zip + ' ' + country;
}
const addComma = (text: string): string => {
    return text ? (text + ', ') : text;
}
// ignore non string fields (address_id, is_default, etc)
const isInList = (record: AddressRecord, list: AddressRecord[]) => {
    list.forEach(address => {
        if (equals(address, record)) {
            return true;
        }
    });
    return false;
}
export default {
    collect,
    scatter,
    getFieldType,
    toArray,
    toHtml,
    isEmpty,
    isNotUSA,
    equals,
    toSingleString,
    toFormattedString,
    isInList
};
