import React from 'react';
import styled from 'styled-components';

import useShoppingCart from './useShoppingCart';
import AddressEditor, { AddressSchemaType } from '../../libs/forms/AddressEditor';
import FormMgr from '../forms/FormMgr';
import { useGlobalContext } from '../SamState';

import { AddressRecord } from '../../interfaces/lib-api-interfaces';

import app from '../../appData';

const StyledMasterContainer = styled.div<{ $backColor: string }>`
    background-color: ${props => props.$backColor};
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    h2 {
        margin: 0;
        font-size: 24px;
    }
`
const buttonStyle = { fontSize: 14, height: 48, margin: 16 };
const StyledSaveChangesDiv = styled.div<{ $backColor: string }>`
    margin-bottom: 16px;
    width: 100%;
    button {
        background-color: ${props => props.$backColor};
        font-size: 14px;
        height: 26px;
    }
`
const StyledAddressesContainer = styled.div<{ $backColor: string }>`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    button {
        background-color: ${props => props.$backColor};
        font-size: 12px;
    }
    p {
        margin: 0;
    }
`
/*  following allows editing of bill address and selection/editing of ship address
    props required:
        handleExit callback
*/
interface OrderAddressEditorProps {
    handleExit: () => void;
}
export const OrderBillAddressEditor: React.FC<OrderAddressEditorProps> = (props) => {
    const forms = new FormMgr(useGlobalContext().setContext);
    const cart = useShoppingCart();

    const order = cart.getOrder();

    const handleSubmit = (values: AddressRecord | null) => {
        if (values) {
            order.bill_address = values;
            cart.setOrder(order);
        }
        props.handleExit();
    }

    return (
        <StyledMasterContainer $backColor={app.themes.backColor10}>
            <h2>Edit Billing Address</h2>
            <AddressEditor id={"bill"} values={order.bill_address} schemaType={AddressSchemaType.wholesale} excludedFields={["is_default"]} companyRequired={true} onSubmit={handleSubmit} />
        </StyledMasterContainer>
    );
}
//-----------------------------------------------------------------

