import React from 'react';

import Contact, { ContactSectionText } from '../libs/Contact';

import app from '../appData';
import apiUrl from '../api-url';
import MasterPage from '../libs/MasterPage';

const ContactUs: React.FC = () => {
    const jmcContactText: ContactSectionText[] = [
        {
            header: "Call, fax or email", paragraphs: [
                "Phone (800) 627-5840",
                "Fax (510) 529-4833",
                'Email <a href="mailto:info@jacobschimes.com">info@jacobschimes.com</a>'
            ]
        },
        {
            header: "Write", paragraphs: [
                "Jacob's Musical Chimes",
                "1831 Solano Ave, Ste A",
                "Berkeley, CA 94707"
            ]
        },
        {
            header: null, paragraphs: [
                "If you need info on getting a broken Jacob's chime repaired, <a style='text-decoration: underline' href='/repair'>click here.</a>"
            ]
        }
    ];

    return (
        <MasterPage>
            <Contact apiUrl={apiUrl.contact} dividerImageFilename={app.twigDividerImage} text={jmcContactText} />
        </MasterPage>
    )
}
export default ContactUs;