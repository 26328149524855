import { DivisionEnum } from "./interfaces/lib-api-interfaces";

const fetchOrder = "/api/getPendingOrder";        // since HTM has no online ordering this is a placeholder only
const fetchProducts = "/api/products/";
const fetchCategories = "/api/categoriesAsArray/";
const register = "/api/register";
const login = "/api/login";
const postShipTo = "/api/postShipTos";
const submitOrder = "/api/postOrder";
const contact = "/api/contact";
const search = "/api/wsSearch";
const storeNearYou = "/api/store_near_you";
const saveAddressBook = "/api/saveAddressBook";
const getCouponValue = "/api/wsGetCouponValue";
const getInfoContent = "/api/getInfoContent/" + DivisionEnum.wholesale + "/";

// dashboard
const uploadImage = "/api/uploadImage";


export default {
    fetchOrder,
    fetchProducts,
    fetchCategories,
    register,
    login,
    postShipTo,
    submitOrder,
    contact,
    search,
    storeNearYou,
    saveAddressBook,
    getCouponValue,
    uploadImage,
    getInfoContent
}