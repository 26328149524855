import React from 'react';
import styled from 'styled-components';

import MasterPage from './MasterPage';

const StyledContainer = styled.div`
    max-width: 600px;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    p {
        margin-top: 0;
        margin-bottom: 0;
    }
    h2 {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 8px;
    }
    h3 {
        font-weight: bold;
        font-size: 14px;
    }
`
const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 16px;
`
const StyledColumn = styled.div` 
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    justify-content: center;
`

export class RepDataRecord {
    name: string;
    city: string;
    phone: string;
    fax: string;
    email: string;
    url: string;
    states: string;
    constructor(name: string, city: string, phone: string, fax: string, email: string, url: string, states: string) {
        this.name = name;
        this.city = city;
        this.phone = phone;
        this.fax = fax;
        this.email = email;
        this.url = url;
        this.states = states;
    }
}
interface RepsProps {
    text: RepDataRecord[];
    companyName: string;
}
const Reps: React.FC<RepsProps> = (props) => {
    return (
        <MasterPage>
            <h1>{"Find a " + props.companyName + " rep"}</h1>
            <StyledContainer>
                <StyledRow>
                    <StyledColumn>
                        <h2>Rep</h2>
                    </StyledColumn>
                    <StyledColumn>
                        <h2>States</h2>
                    </StyledColumn>
                </StyledRow>
                {props.text.map((rep => {
                    return (
                        <StyledRow key={rep.name}>
                            <StyledColumn>
                                <p>{rep.name}</p>
                                <p>{rep.city}</p>
                                <p>T:{rep.phone}</p>
                                {rep.fax &&
                                    <p>F:{rep.fax}</p>}
                                <a href={"mailto:" + rep.email}>{rep.email}</a>
                                {rep.url &&
                                    <p><a href={rep.url}>{rep.url}</a></p>}
                            </StyledColumn>
                            <StyledColumn>
                                <h3>{rep.states}</h3>
                            </StyledColumn>
                        </StyledRow>
                    );
                }))}
            </StyledContainer>
        </MasterPage>
    )
}
export default Reps;

