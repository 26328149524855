import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// routes
import Home from './components/Home';
import Search from './libs/Search';
import Products from './libs/Products';
import Stores from './libs/Stores';
import Login from './libs/Login';
import Register from './components/Register';
import Checkout from './libs/shopping-cart/WSCheckout';
import Catalog from './libs/Catalog';
import Retailers from './components/Retailers';
import JmcReps from './components/JmcReps';
import Repair from './components/Repair';
import ContactUs from './components/ContactUs';

const AppRoutes = () => {
    React.useEffect(() => {
        if (window.location.hostname !== "localhost") {
          const paq = (window as any)._paq;
          paq.push(['setCustomUrl', location.pathname]);
          paq.push(['setDocumentTitle', location.pathname]);
          paq.push(['trackPageView']);
        }
      }, [location]);
    
        return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/products/:category/:item" element={<Products />} />
                <Route path="/products/:category" element={<Products />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/search" element={<Search />} />
                <Route path="/stores" element={<Stores />} />
                <Route path="/catalog" element={<Catalog />} />
                <Route path="/retailers" element={<Retailers />} />
                <Route path="/reps" element={<JmcReps />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/repair" element={<Repair />} />
                <Route path="*" element={<Home />} />
            </Routes>
        </BrowserRouter>
    );
}
export default AppRoutes;