import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

import { usePostApi } from './useDataApiV2';
import { StyledErrorText } from './libSupport';
import SamForm from '../libs/forms/SamFormV5';
import MasterPage from './MasterPage';
import { useTokens, useBookmarks, useCookies, useGlobalContext } from './SamState';
import useShoppingCart from '../libs/shopping-cart/useShoppingCart';
import IconButton from './IconButtonV2';
import useAddressBook from '../libs/useAddressBook';

import { WSLoginResponseRecord } from '../interfaces/ws-api-interfaces';
import { LoginError, FormFieldType, FormFieldRecord, AddressType } from '../interfaces/lib-api-interfaces';

import '../App.css';

import app from '../appData';
import api from '../api-url';
import FormMgr from './forms/FormMgr';

const StyledContainer = styled.div`
    text-align: center;
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  display: flex;
  a:link {
      text-decoration: underline !important;
  }
`
const SplitDiv = styled.div`
    margin: 25px;
    flex-basis: 340px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const CaptionText = styled.p`
    margin-bottom: 18px;
`
const ImportantNoteText = styled.p`
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  font-weight: bold;
  padding: 8px;
  box-shadow: 3px 3px 5px 6px #ccc;
  `

export const doLogout = (deleteSessionStore: (key: string) => void, clearToken: () => void, history: any) => {
    clearToken();
    deleteSessionStore("order");
    if (window.location.href.endsWith("/checkout")) {
        history.push("/");
    }
}

const Login: React.FC = () => {
    const { setToken } = useTokens();
    const { post, isPostLoading } = usePostApi();
    const { setCookie } = useCookies();
    const forms = new FormMgr(useGlobalContext().setContext);
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const cart = useShoppingCart();
    const { navigateToBookmark } = useBookmarks();
    const addressBook = useAddressBook();
    const navigate = useNavigate();

    const successfulPost = (loginResult: WSLoginResponseRecord, status: LoginError | undefined) => {
        if (status === LoginError.custNotFound) {
            setErrorMsg("We could not find your email or password");
        } else if (status === LoginError.invalidPassword) {
            setErrorMsg("We could not find your email or password");
        } else {
            const persist = forms.getValue("login", "remember") as boolean;
            setToken(loginResult.token, persist);
            setCookie("company", loginResult.company, 30);
            addressBook.setAddresses(AddressType.ship, loginResult.shipTos);
            if (app.shoppingCart) {
                cart.recalcCart(loginResult.order, cart.getCategories());
                //        console.log("returned from recalcCart");
            }
            //    console.log("navigating to bookmark");
            navigateToBookmark();
        }
    }
    const failedPost = (message: string) => {
        setErrorMsg(message);
    }
    const handleSubmit = (inputs: Record<string, any> | null, id: string | null) => {
        if (inputs) {
            //          console.log("posting login")
            inputs!.division = app.division;
            post(api.login, inputs, successfulPost, failedPost);
        } else {
            // user canceled
            navigateToBookmark();
        }
    }
    const formData: FormFieldRecord[] = [
        { name: "email", label: "Email Address", size: { widthPct: 98 }, validator: { required: true, maxLength: 50 } },
        { name: "password", label: "Password", size: { widthPct: 98 }, validator: { isPassword: true, required: true, maxLength: 50 } },
        { name: "remember", type: FormFieldType.checkbox, label: "Remember me on this device" }
    ];

    return (
        <MasterPage>
            <h1>Sign in or Register</h1>
            <h3>Sign-in is required if you wish to place an online order</h3>
            <ImportantNoteText>Import note to regular customers: We have recently improved the security of our log-in process and now require an email and a password. If
                you have previously registered with a user name, please <Link to="/register" style={{color: "blue", textDecoration: "underline"}}>re-register</Link> with your email. We apologize for any inconvenience!
                </ImportantNoteText>
            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
            <StyledContainer>
                <SplitDiv>
                    <CaptionText>I have already registered my email and password. Log in here:</CaptionText>
                    <SamForm id="login" fields={formData} submitButtons={[{ id: "submit", caption: "Sign in", icon: "fas fa-check", validate: true }]}
                        handleSubmit={handleSubmit} />
                </SplitDiv>
                <SplitDiv>
                    <CaptionText>I am an existing customer but I need to register my email and create a password.</CaptionText>
                    <IconButton caption="Create user name and password" style={{ fontSize: "16px", marginBottom: "32px" }} icon="fa fa-sign-in"
                        onClick={() => navigate("/register")} />
                    <CaptionText>
                        New to Jacob's Musical Chimes? <a href="/contact">Contact us!</a>
                    </CaptionText>
                </SplitDiv>
            </StyledContainer>
        </MasterPage>
    )
}
export default Login;
