import React from 'react';
import styled from 'styled-components';

import { getNearestParentId } from '../libSupport';
import IconButton from '../IconButtonV2';
import RifmNumeric from '../forms/RifmNumeric';
import { formatImageUrl } from '../ImageFormatter';

import { ShoppingCartListRecord } from '../../interfaces/lib-react-interfaces';
import { FormFieldType, ImageSizeEnum } from '../../interfaces/lib-api-interfaces';
import { useNavigate } from 'react-router-dom';

export const cartListWidth = 370;       // make this the flex basis if position is not absolute or use to make sure it doesn't go off right edge

// parent is responsible for keeping totals and display at bottom
// parent must provide callbacks for item quantities changed
const MasterContainer = styled.div<{ $position: string; $top: number; $right: number }>`
    position: ${props => props.$position};
    background-color: white;
    margin-left: 16px;      /* will only affect position relative */
    top: ${props => props.$top}px;   /* next 2 only for position absolute */
    right: ${props => props.$right}px;
    display: flex;
    flex-direction: column;
    width: ${cartListWidth}px;
    z-index: ${props => props.$position === "absolute" ? 2000 : null};
    box-shadow: 3px 3px 5px 6px #ccc;
`
const CloseBox = styled.div`
    font-size: 40px;
`
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    height: 68px;
`
const ButtonsLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`
const GridRow = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ccc;
    align-items: center;
    padding: 8px;
`
const ImageContainer = styled.div`
    display flex;
    justify-content: center;
    align-items: center;
    height: 94px;
    width: 94px;
    img {
        max-height: 90%;
        max-width: 90%;
        height: auto;
        width: auto;
        }
`
const TextAndFormContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`
const DescriptionAndRemoveContainer = styled.div`
    display: flex;
    justify-content: space-between;
`
const DescriptionText = styled.p`
    margin-top: 0;
    margin-bottom: 8px;
    text-align: left;
    font-weight: bold;
`
const RemoveIcon = styled.i`
    font-size: 18px;
    width: 22px;
    cursor: pointer;
    color: #888;
`
const QtyPriceTotalContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 8px;
`
const QtyContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
interface SaveCartComponent extends React.ComponentClass {
    cartSaved: () => void;
}
interface ShoppingCartListProps {
    items: ShoppingCartListRecord[];
    positionAbsolute?: boolean;     // if true the popup is positioned to right side of screen and a "close" button is added
    scrollToTop?: boolean;          // for mobile
    cartItemQtyChanged: (id: string, qty: number) => void;
    cartItemRemoved: (id: string) => void;
    // following 3 buttons are displayed if handlers are passed in
    checkoutClicked?: () => void;      // if passed, checkout button is shown
    closeClicked?: () => void;      // required if positionAbsolute is true; if not true the close button is shown
    saveOrderClicked?: () => void;
    // following required if saveOrderClicked passed in
    renderSaveOrderStatus?: React.ComponentType<any>;
    // following called at bottom of list to show totals
    renderTotals?: React.FC<any>;
}
const ShoppingCartList: React.FC<ShoppingCartListProps> = (props) => {
    const [right, setRight] = React.useState<number>(0);
    const containerRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    const navigate = useNavigate();

    React.useEffect(() => {
        const left = containerRef.current!.getBoundingClientRect().left;
        if (left < 0) {
            setRight(left);     // bring it back right by the amount it's over the left edge
        }
        if (props.positionAbsolute || props.scrollToTop) {
            window.scrollTo(0, 0);
        }
    }, []);

    const qtyChanged = (qty: number | string, id: string) => {
        props.cartItemQtyChanged(id, qty as number);
    }
    const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLElement;
        props.cartItemRemoved(target.id);
    }
    let position: string;
    let top: number;
    if (props.positionAbsolute) {
        position = "absolute";
        top = 36;
    } else {
        position = "relative";
        top = 0;
    }

    const rowClicked = (e: React.MouseEvent<HTMLElement>) => {
        const skuLong = getNearestParentId(e.target as HTMLElement).id;
        navigate("/" + skuLong);
    }

    const buttonStyle = { margin: "8px", height: "46px", fontSize: "14px" }
    return (
        <MasterContainer ref={containerRef} $top={top} $right={right} $position={position}>
            {(props.checkoutClicked || props.saveOrderClicked || props.positionAbsolute || props.closeClicked) &&
                <ButtonContainer>
                    <ButtonsLeft>
                    {(props.positionAbsolute || props.closeClicked) &&
                        <React.Fragment>
                            <IconButton style={buttonStyle} caption="Continue shopping" onClick={props.closeClicked} />
                        </React.Fragment>
                    }
                    {props.saveOrderClicked &&
                        <IconButton style={buttonStyle} caption="Save for later" onClick={props.saveOrderClicked} />
                    }
                    {props.checkoutClicked &&
                        <IconButton style={buttonStyle} caption="Check out" onClick={props.checkoutClicked} />
                    }
                    </ButtonsLeft>
                    {(props.positionAbsolute || props.closeClicked) &&
                        <CloseBox onClick={props.closeClicked}><i className="far fa-window-close"></i></CloseBox>
                    }
                </ButtonContainer>
            }
            {props.renderSaveOrderStatus && <props.renderSaveOrderStatus />}
            <GridContainer>
                {props.items.map(item => {
                    return (
                        <GridRow key={item.id} id={item.id}>
                            <ImageContainer>
                                <img src={formatImageUrl(item.imageFilename, { sizeDesignator: ImageSizeEnum.full })} alt={item.caption} onClick={rowClicked} />
                            </ImageContainer>
                            <TextAndFormContainer>
                                <DescriptionAndRemoveContainer>
                                    <DescriptionText onClick={rowClicked}>
                                        {item.caption}
                                    </DescriptionText>
                                    <RemoveIcon className="fas fa-times-circle" id={item.id} onClick={handleRemove} />
                                </DescriptionAndRemoveContainer>
                                <QtyPriceTotalContainer>
                                    <QtyContainer>
                                        <RifmNumeric name={item.id} fieldType={FormFieldType.int} textAlign="center" initialValue={item.qty} width={50} height={28} 
                                            showChevrons={true} onChange={qtyChanged} />
                                    </QtyContainer>
                                    <span>&nbsp;&nbsp;@&nbsp;${item.price.toFixed(2)} = ${(item.price * item.qty).toFixed(2)}&nbsp;&nbsp;
                                    </span>
                                </QtyPriceTotalContainer>
                            </TextAndFormContainer>
                        </GridRow>
                    )
                })}
            </GridContainer>
            {props.renderTotals && <props.renderTotals />}
        </MasterContainer>
    )
}

export default ShoppingCartList;