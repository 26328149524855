import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { useFetchApi } from './useDataApiV2';
import { useBookmarks, useTokens } from './SamState';
import MasterPage from './MasterPage';
import '../App.css';
import { StyledErrorText, formatItemNo, deformat, iPadMinWidth, iPhoneWidth, iPadMaxWidth } from './libSupport';
import IconButton from './IconButtonV2';
import useShoppingCart from '../libs/shopping-cart/useShoppingCart';
import { ModalMessageBox, ModalMessageResponseFlags } from '../libs/SamModalV2';
import { FormFieldType, GraphicDimensionType, ImageSizeEnum } from '../interfaces/lib-api-interfaces';
import RifmNumeric from './forms/RifmNumeric';
import BuyBox from './BuyBox';
import { formatImageUrl } from './ImageFormatter';

import { WSCategoriesRecord, WSCategoryRecord, WSOrderRecord, WSProductRecord } from '../interfaces/ws-api-interfaces';

import app from '../appData';
import api from '../api-url';
import { IconButtonAlignEnum } from '../interfaces/lib-react-interfaces';

const maxAltImages = 5;
const SiloCategory = 'S';
const DoorChimeCategory = 'C';

interface InMemProductRecord {
    images: string[];
    item_no: string;
    caption: string;
    prepack_desc?: string;
    bulletPoints?: string;
}
interface PriceDataRecord {
    price: number;
    discountedPrice: number;
}
const StyledCopy = styled.p`
    font-size: 14px;
    line-height: 22px;
    max-width: 940px;
    text-align: left;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: ${iPadMaxWidth}px) {
        font-size: 12px;
        line-height: 16px;
    }
`
const TitleText = styled.h1`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    line-height: 32px;
    @media screen and (max-width: ${iPadMaxWidth}px) {
        font-size: 22px;
        line-height: 26px;
    }
    @media screen and (max-width: ${iPhoneWidth}px) {
        font-size: 16px;
        line-height: 18px;
    }
`
const PriceText = styled.p`
    font-size: 18px;
    line-height: 30px
    text-align: center;
    @media (max-width: ${iPadMaxWidth}) {
        font-size: 16px;
        line-height: 20px;
    }
    @media (max-width: ${iPhoneWidth}) {
        font-size: 13px;
        line-height: 15px;
    }
`
const StyledMasterContainer = styled.div<{ $foreColor: string }>`
    display: flex;
    flex-wrap: wrap;
    color: ${props => props.$foreColor};
    position: relative;
    justify-content: center;
`
const StyledProductCard = styled.div<{ $flexAlign: string }>`
    flex: 1;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.$flexAlign};       /* flex-start or flex-end for top or bottom thumb align */
`
const StyledThumb = styled.img<{ $maxImgSize: number }>`
    max-height: ${props => props.$maxImgSize}px;
    max-width: ${props => props.$maxImgSize}px;
    height: auto; 
    width: auto;
    padding: 16px;
    cursor: pointer;
`

/* url: /products/category/item
        where category is required one letter category code or category url as returned from api
                item is optional 4 letter item num (could have a dash)
                pass item = "index" to show all items in category
*/
const Products: React.FC = () => {
    const params = useParams();
    const category = params.category as string;
    const item = params.item as string;

    const [urlParams, setUrlParams] = React.useState<{ category?: string; item?: string }>({});       // {param1, param2}
    const [products, setProducts] = React.useState<WSProductRecord[]>();
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [addToCartModalText, setAddToCartModalText] = React.useState<string>();
    const [fetchingProducts, setFetchingProducts] = React.useState(false);

    const { fetch } = useFetchApi();
    const { setBookmark } = useBookmarks();
    const { getToken } = useTokens();
    const navigate = useNavigate();

    const cart = useShoppingCart();

    const token = getToken();

    const fetchFailed = () => {
        setErrorMsg("We're so sorry. There were supposed to be photos here but we could not load them. Please try again later!");
    }
    React.useEffect(() => {
        if (fetchingProducts) {
            fetch(api.fetchProducts + app.division + "/" + cart.getCategoryFromUrl(category), null, (result: WSProductRecord[]) => {
                //         console.log("result:"); console.log(result);
                setProducts(result);
            }, fetchFailed);
            setFetchingProducts(false);
        }

    }, [fetchingProducts]);

    React.useEffect(() => {
        // if categories aren't in yet, not to worry as MasterPage will get them and rerender
        if (cart.categoriesExist()) {
            // allow url format "/products/ITEM-ID"
            if (!item && (category.length === 5 || category[0].toLowerCase() === 'y') && category[1] === '-') {
                const prodCategory = category[0].toLowerCase() === 'y' ? category[2] : category[0];
                navigate("/products/" + categories[prodCategory].url + "/" + deformat(category));
            } else {
                if (urlParams.category !== category) {
                    setFetchingProducts(true);
                    setUrlParams({ category: category, item: item });
                }
                if (urlParams.item !== item) {
                    setUrlParams({ category: category, item: item });
                }
            }
        }
        // MasterPage should do the following
        // fetch order if appropriate
        // if (app.shoppingCart && token) {
        //     if (!cart.getOrder()) {
        //         fetch(api.fetchOrder + "/" + token.userId, token.token, (result: WSOrderRecord) => cart.setOrder(result), fetchFailed);
        //     }
        // }
    });

    const getItemIndex = (itemNo: string): number => {
        for (let index = 0; index < products!.length; index++) {
            if (products![index].item_no === itemNo) {
                return index;
            }
        }
        return -1;
    }

    const order: WSOrderRecord = cart.getOrder();
    const categories: WSCategoriesRecord = cart.getCategories();

    if (categories && !cart.getCategoryFromUrl(category)) {
        navigate('/');     // invalid category
        return null;
    }

    const thankYouButtonClicked = (response: ModalMessageResponseFlags) => {
        setAddToCartModalText(undefined);
        if (response === ModalMessageResponseFlags.checkout) {
            navigate('/checkout');
        } else {
            handleContinueShopping();
        }
    }

    const handleAddToCart = (itemNo: string, qty: number, caption: string, mainImage: string) => {
        cart.addToCart(deformat(itemNo), qty, caption, mainImage);
        // display "thank you" popup with buttons for continue shopping and view cart
        setBookmark('');
        setAddToCartModalText("Thank you for adding " + qty + " " + caption + (qty > 1 ? 's' : '') + " to your cart.");
    }
    const handleContinueShopping = () => {
        window.location.href = "/products/" + category;
    }
    const handleLogIn = () => {
        setBookmark("/products/" + category + "/" + item);
        navigate("/login");
    }
    const handleThumbClick = (e: React.MouseEvent<HTMLImageElement>) => {
        const target = e.target as HTMLImageElement;
        navigate("/products/" + category + "/" + target.id);
    }

    let priceData = null;
    let productData: InMemProductRecord | null = null;
    if (products && categories && item) {
        const index = getItemIndex(item);
        const images = [products[index].main_image, ...products[index].altImages];
        productData = {
            item_no: formatItemNo(products[index].item_no),
            caption: products[index].caption,
            images
        };
        const prodCategory = cart.getCategoryFromUrl(category);
        if (products[index].item_no[0] === 'Y') {
            productData.prepack_desc = categories[prodCategory]["prepack_desc"];
        } else {
            productData.bulletPoints = categories[prodCategory]["bullet_points"];
        }
        if (token) {
            priceData = (products[index].item_no[0] === 'Y' ?
                { price: categories[prodCategory].prepack_price } as PriceDataRecord :
                { price: categories[prodCategory].price, discountedPrice: categories[prodCategory].discounted_price } as PriceDataRecord);
        }
    }

    const handleNextClick = () => {
        let newSelected = getItemIndex(item!) + 1;
        if (newSelected >= products!.length) {
            // following assumes that best seller pack is item #0 (items were fetched in display_order)
            newSelected = token ? 0 : 1;
        }
        navigate("/products/" + category + "/" + products![newSelected].item_no);
    }
    const handlePrevClick = () => {
        let newSelected = getItemIndex(item!) - 1;
        // following assumes that best seller pack is item #0 (items were fetched in display_order)
        if (newSelected < 0 || (newSelected === 0 && !token)) {
            newSelected = products!.length - 1;
        }
        navigate("/products/" + category + "/" + products![newSelected].item_no);
    }

    const currCategory = cart.getCategoryFromUrl(category);
    const prepackText = currCategory !== DoorChimeCategory && currCategory !== SiloCategory ? " (discounted prepack also available)" : ''

    //  console.log("categories[currCategory]:"); console.log(categories[currCategory]);
    return (
        <MasterPage>
            {categories && products && (
                productData ? (
                    <BuyBox product={{ caption: productData.caption, bullet_points: productData.bulletPoints, images: productData.images }}
                        infoBox={InfoBox as React.FC} infoBoxProps={{
                            productData, priceData, categoryDesc: categories[currCategory].short_desc,
                            buyOnlineUrl: token ? null : categories[currCategory].ferns_garden_url,
                            addToCart: app.shoppingCart && token ? handleAddToCart : null, continueShopping: handleContinueShopping,
                            logIn: app.shoppingCart && !token ? handleLogIn : null,
                            nextClick: handleNextClick, prevClick: handlePrevClick
                        }} />
                ) : (
                    <React.Fragment>
                        <h1>{categories[currCategory].long_desc}</h1>
                        {token && categories && products && categories[currCategory].discounted_price &&
                            <PriceText>${categories[currCategory].price.toFixed(2) + prepackText}</PriceText>}
                        <StyledCopy>{categories[currCategory].product_copy}</StyledCopy>
                        <StyledMasterContainer $foreColor={app.themes.foreColor} id="products">
                            {errorMsg &&
                                <StyledErrorText>{errorMsg}</StyledErrorText>}
                            {products &&
                                products.map((product) => {
                                    return (
                                        (product.item_no[0] !== 'Y' || token) &&
                                        <ProductCard key={product.item_no} itemNo={product.item_no}
                                            caption={product.caption}
                                            thumb={formatImageUrl(product.main_image, { sizeDesignator: ImageSizeEnum.full })}
                                            thumbSize={categories[currCategory].thumb_size} thumbDimension={categories[currCategory].thumb_dimension}
                                            handleThumbClick={handleThumbClick}>
                                        </ProductCard>
                                    );
                                })}
                        </StyledMasterContainer>
                    </React.Fragment>
                ))
            }
            {addToCartModalText &&
                <ModalMessageBox
                    caption={addToCartModalText}
                    responseFlags={ModalMessageResponseFlags.checkout | ModalMessageResponseFlags.continueShopping}
                    onSubmit={thankYouButtonClicked}
                />
            }
        </MasterPage>
    );
}
interface InfoBoxProps {
    productData: InMemProductRecord;
    priceData: PriceDataRecord | null;
    categoryDesc: string;
    buyOnlineUrl: string | null;
    continueShopping: () => void;
    addToCart: ((itemNo: string, qty: number, caption: string, image: string) => void) | null;
    nextClick: () => void;
    prevClick: () => void;
    logIn: () => void | null;
}
const InfoBox: React.FC<InfoBoxProps> = (props) => {
    const handleAddToCart = (e: React.MouseEvent<HTMLButtonElement>) => {
        const qtyInput = document.getElementsByTagName("input")[0];
        props.addToCart!(props.productData.item_no, parseInt(qtyInput.value), props.productData.caption, props.productData.images[0]);
    }
    const handleNextPrevClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLInputElement;
        if (target.innerText.startsWith("Next")) {
            props.nextClick();
        } else {
            props.prevClick();
        }
    }
    const handleBuyOnline = () => {
        window.open(props.buyOnlineUrl!);
    }
    const nextPrevStyles = { height: "24px", fontSize: "12px", margin: "8px" };
    const buttonStyles = { display: "block", paddingLeft: "24px", paddingRight: "24px", fontSize: "18px", margin: "12px auto 12px auto" };
    const buttonStylesWithHeight = { ...buttonStyles, height: "32px" };
    return (
        <StyledTextContainer id="text">
            <StyledNextPrevButtons>
                <IconButton caption="Previous" onClick={handleNextPrevClick} icon="fas fa-chevron-left" style={nextPrevStyles} />
                <IconButton caption="Next" onClick={handleNextPrevClick} icon={"fas fa-chevron-right"} iconPosition={IconButtonAlignEnum.right} style={nextPrevStyles} />
            </StyledNextPrevButtons>
            {props.priceData && props.priceData.price && (
                <React.Fragment>
                    <StyledPriceText $fontSize={14}>{props.productData.item_no}</StyledPriceText>
                    <StyledPriceText $fontSize={22}>${props.priceData.price.toFixed(2)}</StyledPriceText>
                    {props.priceData.discountedPrice &&
                        <StyledPriceText $fontSize={12}></StyledPriceText>}
                </React.Fragment>
            )}
            {props.addToCart && (
                <React.Fragment>
                    <QtyForm />
                    <IconButton caption="Add to cart" onClick={handleAddToCart} icon="fas fa-shopping-cart" style={buttonStylesWithHeight} />
                </React.Fragment>
            )}
            {props.logIn &&
                <IconButton caption="Retailers: Log in to activate shopping cart" onClick={props.logIn} icon="fas fa-sign-in-alt" style={buttonStyles} />}
            {props.buyOnlineUrl &&
                <IconButton caption="Shop now at fernsgarden.com" onClick={handleBuyOnline} icon={formatImageUrl("fern.gif", { graphicsSubfolder: "other" })} style={buttonStyles} />}
            <IconButton caption={"See all " + props.categoryDesc + (props.categoryDesc.endsWith('s') ? '' : 's')}
                onClick={props.continueShopping} icon={"fas fa-check"} style={buttonStyles} />
            {props.productData.prepack_desc && (
                <React.Fragment>
                    <StyledPrepackDesc $topMargin={16}><b>Best seller pack contains:</b></StyledPrepackDesc>
                    {props.productData.prepack_desc.split("@").map(text => {
                        return (
                            <StyledPrepackDesc $topMargin={0} key={text}>{text}</StyledPrepackDesc>
                        );
                    })}
                </React.Fragment>
            )}
        </StyledTextContainer>
    );
}

interface ProductCardProps extends PropsWithChildren {
    thumbSize: number;
    thumbDimension: GraphicDimensionType;
    itemNo: string;
    thumb: string;
    caption: string;
    handleThumbClick: (e: React.MouseEvent<HTMLImageElement>) => void;
}
const ProductCard: React.FC<ProductCardProps> = (props) => {
    //    console.log("ProductCard " + props.caption + ", size=" + props.thumbSize + "/" + props.thumbDimension);
    return (
        <StyledProductCard $flexAlign={app.alignProducts === "bottom" ? "flex-end" : "flex-start"}>
            <StyledThumb id={props.itemNo} src={props.thumb} $maxImgSize={props.thumbSize} alt={props.caption} onClick={props.handleThumbClick}></StyledThumb>
            <div>{props.caption}</div>
        </StyledProductCard>
    );
}
/*
const StyledProductPopupContainer = styled.div<{ $$fontSize: number; $lineHeight: number; $foreColor: string; $font: string }>`
    display: flex;
    background-color: white;
    margin: 12px auto 0 auto;
    padding: 8px;
    width: 100%;
    max-width: 818px;
    font-size: ${props => props.$fontSize}px;
    line-height: ${props => props.$lineHeight}px;
    color: ${props => props.$foreColor};
    font-family: ${props => props.$font};
    border: 1px solid ${props => props.$foreColor};
    @media screen and (max-height: 680px) {
        margin-top: 8px;
    }
`
//--------- MAIN IMAGE AND THUMBS (LEFT SIDE) --------
const StyledMainAndThumbsContainer = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`
const StyledMainImgContainer = styled.div`
    width: 100%;
    text-align: center;
`
const StyledMainImage = styled.img`
    height: auto;
    max-height: 500px;
    width: auto;
    cursor: pointer;
    max-width: 100%;
`
const ClickToEnlargeText = styled.p`
    margin: 2px;
    text-align: center;
`
const StyledThumbsContainer = styled.div`
    margin-top: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
`
const StyledThumbBox = styled.div`
    border: 1px solid;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 75px;
`
const StyledProductPopupThumb = styled.img`
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    margin: 8px;
`
//--------- TEXT AND MAGNIFIED (RIGHT SIDE) ----------
const StyledTextAndMagContainer = styled.div`
    width: 55%;
`
*/
//--------- TEXT -----------------------------
const StyledTextContainer = styled.div`
    width: 100%;
    text-align: center;
    vertical-align: top;
`
const StyledNextPrevButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
`
const StyledPriceText = styled.p<{ $fontSize: number }>`
    font-size: ${props => props.$fontSize}px;
    margin-top: 8px;
    margin-bottom: 8px;
`
// qty label and input
const StyledQtyForm = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 16px;
    margin-top: 16px;
`
const StyledQtyLabel = styled.p`
    font-size: 18px;
    margin-top: 4px;
    margin-bottom: 0;
    margin-right: 4px;
`
const StyledBulletPoints = styled.ul`
    li {
        margin-bottom: 8px;
        text-align: left;
    }
`
const StyledPrepackDesc = styled.p<{ $topMargin: number }>`
    font-size: 14px;
    text-align: center;
    margin: ${props => props.$topMargin}px 4px 0 0;
    line-height: 20px;
`
/* props:
    productData : {
        caption : string, 
        item_no : string, 
        images : string[] (up to 6 images, can be video),
        bulletPoints : string, newline separated points -- optional
        prepack_desc : string in prepack format (@ delimited) -- optional
        categoryDesc : "view all xxx" button -- REQUIRED
        buyOnlineUrl : url to purchase this item online -- optional
        buyOnlineText : text of "buy online" button -- required if buyOnlineUrl given
    }
    priceData : { price : string, discountedPrice : string (can be null) }  -- optional -- prices are formatted with $
 
    callbacks:
    addToCart(itemNo : string, qty : number) -- optional (displays button if given, "login to add to cart" if not given)
    continueShopping()
    logIn() -- if supplied a log in button is shown
    nextClick()
    prevClick()
 */
/*
interface ProductPopupProps {
    productData: InMemProductRecord;
    priceData: PriceDataRecord | null;
    categoryDesc: string;
    buyOnlineUrl: string | null;
    continueShopping: VoidCallback;
    addToCart: ((itemNo: string, qty: number, caption: string, image: string) => void) | null;
    nextClick: VoidCallback;
    prevClick: VoidCallback;
    logIn: VoidCallback | null;
}
const ProductPopup: React.FC<ProductPopupProps> = (props) => {
    const [mainImageIndex, setMainImageIndex] = React.useState<number>(0);     // index into images for main image
    const [mediaWidth, setMediaWidth] = React.useState<string>('L');      //  'L/M/S'
    const [textHeight, setTextHeight] = React.useState<number>(0);
    const [isMagShown, setIsMagShown] = React.useState<boolean>(false);
    const [mainImgRect, setMainImgRect] = React.useState<Record<string, any> | null>(null);
    const mainImgContainerRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    const medWidth = window.matchMedia("(max-width: 600px)");
    const smallWidth = window.matchMedia("(max-width: 480px)");
    let fontSize = 14;
    let lineHeight = 18;
    let h3FontSize = 28;
    let h3LineHeight = 30;
    const testMedia = () => {
        let width = 'L';
        if (smallWidth.matches) {
            fontSize = 11;
            lineHeight = 12;
            h3FontSize = 14;
            h3LineHeight = 15;
            width = 'S';
        }
        else if (medWidth.matches) {
            fontSize = 12;
            lineHeight = 14;
            h3FontSize = 18;
            h3LineHeight = 20;
            width = 'M';
        }
        if (mediaWidth !== width) {
            setMediaWidth(width);       // force re-render
        }
    }
    //  testMedia();
    // window.addEventListener('resize', () => {
    //     testMedia();
    // });

    React.useEffect(() => {
        if (mainImgContainerRef.current) {
            const rect = mainImgContainerRef.current.getBoundingClientRect();
            if (!mainImgRect || mainImgRect.left !== rect.left || mainImgRect.top !== rect.top) {
                setMainImgRect(rect);
            }
        }
        const elem = document.getElementById("text");
        if (elem) {
            //              console.log("setting textHeight to " + elem.getBoundingClientRect().height);
            const height = elem.getBoundingClientRect().height;
            if (textHeight !== height) {
                setTextHeight(height);
            }
        }
    });

    const handleThumbMouseOver = (e: React.MouseEvent<HTMLImageElement>) => {
        // set index for clicked image to become main image; when rendering thumbs this index will be skipped
        const target = e.target as HTMLImageElement;
        if (mainImageIndex !== parseInt(target.id)) {
            setMainImageIndex(parseInt(target.id));
        }
    }
    const handleExitClick = () => {
        props.continueShopping();
    }
    const handleAddToCart = (e: React.MouseEvent<HTMLButtonElement>) => {
        const qtyInput = document.getElementsByTagName("input")[0];
        props.addToCart!(props.productData.item_no, parseInt(qtyInput.value), props.productData.caption, props.productData.images[0]);
    }
    const handleNextPrevClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLInputElement;
        if (target.innerText.startsWith("Next")) {
            props.nextClick();
        } else {
            props.prevClick();
        }
    }
    const handleMainImgClick = () => {
        setIsMagShown(state => !state);
    }
    const handleMagImageExitClick = () => {
        setIsMagShown(false);
    }
    const handleBuyOnline = () => {
        window.location.href = props.buyOnlineUrl!;
    }

    let bulletPoints = null;
    if (props.productData.bulletPoints) {
        bulletPoints = (props.productData.bulletPoints.endsWith('\n') ? props.productData.bulletPoints.slice(0, -1) : props.productData.bulletPoints).split('\n');
    }

    const nextPrevStyles = { height: 24, fontSize: 12, margin: 8 };
    const buttonStyles = { display: "block", paddingLeft: 24, paddingRight: 24, fontSize: 18, height: 32, margin: "12px auto 12px auto" };
    const mainImageSrc = formatImageUrl(props.productData.images[mainImageIndex], ImageSizeEnum.full);
    const magImageSrc = formatImageUrl(props.productData.images[mainImageIndex], ImageSizeEnum.magnified);

    //    console.log("props.priceData:", props.priceData);

    return (
        <StyledProductPopupContainer $foreColor={app.themes.foreColor} $font={app.themes.defaultFonts} $fontSize={fontSize} $lineHeight={lineHeight} >
            {isMagShown &&
                <MagnifiedImage top={0} left={mainImgRect!.left + mainImgRect!.width} height={mainImgRect!.height * 2}
                    width={mainImgRect!.width * 2} src={magImageSrc} alt={props.productData.caption}
                    handleMagImageExitClick={handleMagImageExitClick} />}
            <StyledMainAndThumbsContainer>
                <StyledMainImgContainer ref={mainImgContainerRef}>
                    <StyledMainImage src={mainImageSrc} alt={props.productData.caption} onClick={handleMainImgClick} />
                </StyledMainImgContainer>
                <ClickToEnlargeText>Click image to enlarge</ClickToEnlargeText>
                {props.productData.images.length > 1 &&
                    <StyledThumbsContainer>
                        {props.productData.images.map((image, index) => {
                            return (
                                <StyledThumbBox key={image}>
                                    <StyledProductPopupThumb id={index + ''} onMouseEnter={handleThumbMouseOver} src={formatImageUrl(image, ImageSizeEnum.full)} />
                                </StyledThumbBox>
                            )
                        })}
                    </StyledThumbsContainer>}
            </StyledMainAndThumbsContainer>
            <StyledTextAndMagContainer>
                <StyledTextContainer id="text">
                    <StyledHeader fontSize={h3FontSize} lineHeight={h3LineHeight}>{props.categoryDesc}</StyledHeader>
                    <StyledHeader fontSize={h3FontSize} lineHeight={h3LineHeight}>{props.productData.caption}</StyledHeader>
                    <StyledNextPrevButtons>
                        <IconButton caption="Previous" onClick={handleNextPrevClick} icon="fas fa-chevron-left" style={nextPrevStyles} />
                        <IconButton caption="Next" onClick={handleNextPrevClick} icon={"fas fa-chevron-right"} iconPosition={IconPosition.right} style={nextPrevStyles} />
                    </StyledNextPrevButtons>
                    {props.priceData && props.priceData.price && (
                        <React.Fragment>
                            <StyledPriceText $fontSize={14}>{props.productData.item_no}</StyledPriceText>
                            <StyledPriceText $fontSize={22}>${props.priceData.price.toFixed(2)}</StyledPriceText>
                            {props.priceData.discountedPrice &&
                                <StyledPriceText $fontSize={12}>(36 or more assorted {props.categoryDesc}: ${props.priceData.discountedPrice.toFixed(2)} each)</StyledPriceText>}
                        </React.Fragment>
                    )}
                    {props.addToCart && (
                        <React.Fragment>
                            <QtyForm />
                            <IconButton caption="Add to cart" onClick={handleAddToCart} icon="fas fa-shopping-cart" style={buttonStyles} />
                        </React.Fragment>
                    )}
                    {props.logIn &&
                        <IconButton caption="Retailers: Log in to activate shopping cart" onClick={props.logIn} icon="fas fa-sign-in-alt" style={buttonStyles} />}
                    {props.buyOnlineUrl &&
                        <IconButton caption="Shop now at fernsgarden.com" onClick={handleBuyOnline} icon={formatImageUrl("other/fern.gif")} style={buttonStyles} />}
                    <IconButton caption={"See all " + props.categoryDesc + (props.categoryDesc.endsWith('s') ? '' : 's')} onClick={props.continueShopping} icon={"fas fa-check"} style={buttonStyles} />
                    {bulletPoints && (
                        <StyledBulletPoints>
                            {bulletPoints.map((point, index) => {
                                return (
                                    <li key={index}><span dangerouslySetInnerHTML={{ __html: point }}></span></li>
                                )
                            })}
                        </StyledBulletPoints>
                    )}
                    {props.productData.prepack_desc && (
                        <React.Fragment>
                            <StyledPrepackDesc $topMargin={16}><b>Best seller pack contains:</b></StyledPrepackDesc>
                            {props.productData.prepack_desc.split("@").map(text => {
                                return (
                                    <StyledPrepackDesc $topMargin={0} key={text}>{text}</StyledPrepackDesc>
                                );
                            })}
                        </React.Fragment>
                    )}
                </StyledTextContainer>
            </StyledTextAndMagContainer>
        </StyledProductPopupContainer>
    );
}
*/
/* props:
    priceData : {price, discountedPrice}
*/
const QtyForm: React.FC = () => {
    const [qty, setQty] = React.useState(1);
    const qtyChange = (valueAsNumber: number) => {
        setQty(valueAsNumber);
    }
    return (
        <StyledQtyForm>
            <StyledQtyLabel>Quantity:</StyledQtyLabel>
            <RifmNumeric name={"qty"} fieldType={FormFieldType.int} initialValue={1} showChevrons={true}
                allowNegative={false} allowZero={false} textAlign="center" fontSize={24} width={50} height={26}
                onChange={(newQty: number | string) => setQty(newQty as number)} />
        </StyledQtyForm>

    );
}
interface MagnifiedImageProps {
    width: number;
    height: number;
    top: number;
    left: number;
    src: string;
    alt: string;
    handleMagImageExitClick: () => void;
}
const MagnifiedImage: React.FC<MagnifiedImageProps> = (props) => {
    const StyledMasterContainer = styled.div<{ $width: number; $height: number; $top: number; $left: number }>`
        width: ${props => props.$width}px;
        height: ${props => props.$height}px;
        position: absolute;
        top: ${props => props.$top}px;
        left: ${props => props.$left}px;
        z-index: 2200;
        border: 2px solid;
        background-color: white;
    `
    const StyledXContainer = styled.div`
        display: flex;
        width: 100%;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 0;
    `
    const StyledX = styled.div`
        width: 24px;
        height: 24px;
        color: red;
        font-size: 24px;
        line-height: 24px;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid;
    `
    const StyledImageContainer = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
    `
    const StyledImage = styled.img`
        max-height: 1000px;
        max-width: 100%;
        height: auto;
        width: auto;
    `
    return (
        <StyledMasterContainer $width={props.width} $height={props.height} $top={props.top} $left={props.left}>
            <StyledXContainer>
                <StyledX onClick={props.handleMagImageExitClick}>X</StyledX>
            </StyledXContainer>
            <StyledImageContainer>
                <StyledImage src={props.src} alt={props.alt} />
            </StyledImageContainer>
        </StyledMasterContainer>
    )
}
export default Products;

