import React from "react";
import WebFont from "webfontloader";
import Spinner from "./Spinner";
import styled from "styled-components";
import IconButton, { ButtonsRow } from "./IconButtonV2";
import { writeLog } from "./libSupport";
import { isGoogleFont } from "./forms/FontPicker";

import { InfoPageStylesRecord } from "../interfaces/lib-api-interfaces";

const simulate = false;
const simulateError = false;

const isLogging = true;

const simulateLoad = (name: string, props: { index: number, tryCount: number }, success: (props: { index: number, tryCount: number }) => void, fail: (props: { index: number, tryCount: number }) => void) => {
    setTimeout(() => {
        console.log("simulating loading " + name);
        if (!simulateError && name === "Roboto") {
            success(props);
        } else {
            fail(props);
        }
    }, 2000);
}
export const loadFontsFromStringValueList = (fontValues: string[], onDone: (errors: string[]) => void) => {
    const errors: string[] = [];
    tryLoad({ index: 0, tryCount: 0 });
    function tryLoad(props: { index: number, tryCount: number }) {
        if (props.index === fontValues.length) {
            onDone(errors);
        } else {
            const name = fontValues[props.index].split(',')[0];
            if (isFontLoaded(name)) {
                onSuccess(props);
            } else {
                if (simulate) {
                    simulateLoad(name, props, onSuccess, onFail);
                } else {
                    WebFont.load({
                        google: {
                            families: [name]
                        },
                        active: () => onSuccess(props),
                        inactive: () => onFail(props)
                    });
                }
            }
        }
    }
    function onSuccess(props: { index: number, tryCount: number }) {
        props.index++;
        props.tryCount = 0;
        tryLoad(props);
    }
    function onFail(props: { index: number, tryCount: number }) {
        props.tryCount++;
        if (props.tryCount === 3) {
            errors.push(fontValues[props.index]);
            props.tryCount = 0;
            props.index++;
        }
        tryLoad(props);
    }
}
export const formatErrorMessage = (errors: string[]): string => {
    let text = "Unable to load the following fonts: ";
    for (const name of errors) {
        text += name.split(',')[0] + ", ";
    }
    return text.slice(0, -2);
}
// return font family on success, null on error
// pass font as "font-family: xyz;"
export const loadFontFromCallout = (callout: string, onSuccess: (family: string) => void, onFail: (family: string) => void) => {
    if (!callout.startsWith("font-family:")) {
        onFail(callout);
    } else {
        const css = callout.substring(13);
        loadFontsFromStringValueList([css], msg => {
            if (msg) {
                onFail(css);
            } else {
                onSuccess(css);
            }
        });
    }
}

export const isFontLoaded = (family: string): boolean => {
    const it = document.fonts.values()
    let result = it.next();
    while (!result.done) {
        //       console.log("checking:", result.value.family)
        if (result.value.family.replaceAll('"', '') === family) {
            return true;
        }
        result = it.next();
    }
    return false;
}

export const listAllFonts = () => {
    const it = document.fonts.values()
    let result = it.next();
    while (!result.done) {
        console.log(result.value.family.replaceAll('"', ''));
        result = it.next();
    }
}
//----------------------------------------------------
const ModalContainer = styled.div`
    width: 250px;
    height: 250px;
    z-index: 10000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #e9ecc2;
    border: 2px solid #4a5929;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    p {
        font-size: 18px;
        text-align: center;
    }
`
interface LoadFontsModalProps {
    fonts?: string[];                   // either this or next 2 must be valid (pass fonts to bypass scanning html for fonts)
    root?: HTMLElement;
    globalStyles?: InfoPageStylesRecord;
    onDone: (errors: string[]) => void;     // error message (if any) will have shown and been dismissed
}
const LoadFontsModal: React.FC<LoadFontsModalProps> = (props) => {
    const [message, setMessage] = React.useState("Loading fonts");
    const [fontsToLoad, setFontsToLoad] = React.useState<string[]>();
    const [errors, setErrors] = React.useState<string[]>();
    const [showModal, setShowModal] = React.useState(false);

    React.useEffect(() => {
        let fonts = props.fonts ?? [props.globalStyles!.fontFamily, props.globalStyles!.headerFontFamily];
        if (props.root && props.globalStyles) {
            const elems = props.root.getElementsByTagName("*");
            for (let i = 0; i < elems.length; i++) {
                const styles = window.getComputedStyle(elems[i]);
                const elemFonts = styles.getPropertyValue("font-family").replaceAll('"', '').split(',');
                if (!fonts.includes(elemFonts[0])) {
                    fonts.push(elemFonts[0]);
                }
            }
        }
        fonts = fonts.filter(font => {
             return (font !== "serif" && font !== "sans-serif" && isGoogleFont(font));
        });
     //   console.log("FONTS:", fonts)
        if (fonts.length) {
            setFontsToLoad(fonts);
        } else {
            props.onDone([]);
        }
    }, []);

    React.useEffect(() => {
        if (fontsToLoad) {
            loadFontsFromStringValueList(fontsToLoad, loadDone);
            setShowModal(true);
        }
    }, [fontsToLoad]);

    const loadDone = (errorFonts: string[]) => {
        setFontsToLoad(undefined);
        if (errorFonts.length) {
            setMessage(formatErrorMessage(errorFonts));
            setErrors(errorFonts);
        } else {
            props.onDone(errorFonts);
        }
    }
    return (
        <>
            {showModal &&
                <ModalContainer>
                    {fontsToLoad && <Spinner />}
                    <p>{message}</p>
                    {!fontsToLoad &&
                        <ButtonsRow>
                            <IconButton caption="OK" style={{ backgroundColor: "#d3d584" }} onClick={() => props.onDone(errors!)} />
                        </ButtonsRow>}
                </ModalContainer>
            }
        </>
    )

}
export default LoadFontsModal;