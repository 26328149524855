import React from 'react';
import styled from 'styled-components';

import { StyledErrorText, iPadMaxWidth, iPadMinWidth, iPhoneWidth } from './libSupport';
import NavBar, { SlidingNavBar } from './NavBarV4';
import { useTokens, useBookmarks, useSessionStore, useCookies, useGlobalContext } from './SamState';
import SearchPopup from './SearchPopup';
import { doLogout } from './Login';
import { apiStateKey, useFetchApi } from './useDataApiV2';
import useShoppingCart, { noCartPopupWidth } from './shopping-cart/useShoppingCart';
import Spinner from './Spinner';

import { MenuItemData } from '../interfaces/lib-api-interfaces';
import { WSCategoryRecord, WSCustOrderInfoRecord } from '../interfaces/ws-api-interfaces';

import app from '../appData';
import api from '../api-url';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatImageUrl } from './ImageFormatter';

const widgetsBelowLogoWidth = 550;

const StyledMasterContainer = styled.div<{ $defaultFonts: string; $foreColor: string; $h1FontFamily: string | null }>`
    text-align: center;
    font-family: ${props => props.$defaultFonts};
    color: ${props => props.$foreColor};
    @media screen and (max-width: ${iPadMaxWidth}px) {
        text-align: left;
    }
    a:link {
        text-decoration: none; color: ${props => props.$foreColor}
    }
    a:visited {
        text-decoration: none; color: ${props => props.$foreColor}
    }
    a:hover {
        font-style: italic; text-decoration: underline; color: ${props => props.$foreColor}
    }
    h1 {
        font-family: ${props => props.$h1FontFamily};
        text-align: center;
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 4px;
        font-weight: bold;
        @media (max-width: ${iPadMaxWidth}px) {
            font-size: 18px;
            line-height: 22px;
        }
    }
    h3 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 4px;
    @media (max-width: ${iPadMaxWidth}px) {
        font-size: 14px;
    }

`
// banner above logo shown in reverse
const StyledBanner = styled.div<{ $backColor: string; $foreColor: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.$backColor};
    width: 100%;
    color: ${props => props.$foreColor};
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    height: 32px;
    margin-bottom: 0;
    @media (max-width: ${iPadMaxWidth}px) {
        font-size: 12px;
        height: 24px;
    }
`

const ContentAndCart = styled.div`
    display: flex;
    padding-right: 16px;
    flex-direction: row;
`
const StyledContentContainer = styled.div`
    width: 100%;
`
const StyledCartContainer = styled.div`
    width: 350px;
`
interface MasterPageProps extends React.PropsWithChildren<{}> {
    menuLoaded?: () => void;
}
const MasterPage: React.FC<MasterPageProps> = (props) => {
    const [errorMsg, setErrorMsg] = React.useState<string>('');
    const [forceRerender, setForceRerender] = React.useState(false);
    const [fetchedCategories, setFetchedCategories] = React.useState(false);

    const { getSessionStore, setSessionStore, deleteSessionStore } = useSessionStore();
    const { setBookmark } = useBookmarks();
    const { getToken, clearToken } = useTokens();
    const { getCookie } = useCookies();
    const { fetch } = useFetchApi();
    const { getContext } = useGlobalContext();
    const cart = useShoppingCart();
    const navigate = useNavigate();

    const token = getToken();

    const fetchFailed = () => {
        setErrorMsg("We're sorry but our server seems to be down right now. Please try again later.");
    }
    const pendingOrderFailed = () => {
        doLogout(deleteSessionStore, clearToken, history);
    }
    const gotOrder = (result: WSCustOrderInfoRecord) => {
        cart.setOrder(result.order);
        if (cart.getCategories()) {
            cart.recalcCart(result.order, cart.getCategories());
        }
    }
    // load categories and create main menu
    const gotCategories = (records: WSCategoryRecord[]) => {
        const categories = cart.setCategoriesFromArray(records);
        // format main menu using fetched categories
        const prodMenu = [] as MenuItemData[];
        let productsPopupItems = [] as MenuItemData[];
        records.forEach(record => {
            productsPopupItems.push({ caption: record.long_desc, href: "/products/" + record.url });
        });
        if (app.productsNotInDB) {
            productsPopupItems = productsPopupItems.concat(app.productsNotInDB as unknown as MenuItemData[]);
        }
        prodMenu.push({ caption: "PRODUCTS", popupItems: productsPopupItems });
        const menu = prodMenu.concat(app.nonProductMenu);
        setSessionStore("menu", menu);
        if (props.menuLoaded) {
            props.menuLoaded();
        }

        if (cart.getOrder()) {
            cart.recalcCart(cart.getOrder(), categories);
        }
    }
    React.useEffect(() => {
        if (!cart.categoriesExist()) {
            fetch(api.fetchCategories + app.division, null, gotCategories, fetchFailed);
        }
        // handle case of token in cookie ("remember me") with new session (order and cart normally loaded upon login)
        const token = getToken();
        if (token && app.shoppingCart && !cart.getOrder()) {
            fetch(api.fetchOrder + "/" + token.userId, token.token, gotOrder, pendingOrderFailed);
        }
    }, []);

    const isVisible = (menuItem: MenuItemData): boolean => {
        if (!menuItem.userData) {
            return true;
        }
        const userData = menuItem.userData as string;
        const tokenExists = !!token;
        return ((userData === "rtlOnly" && !tokenExists) || (userData === "wsOnly" && tokenExists)
            || (userData === "adminOnly" && tokenExists && token!.userId === app.adminCustId));
    }
    const handleLogin = () => {
        if (getToken()) {
            doLogout(deleteSessionStore, clearToken, history);
        } else {
            setBookmark('');
            navigate("/login");
        }
    }

    const apiState = getContext(apiStateKey);
    const apiStateElapsed = apiState ? (Date.now() - apiState) : 0;
    if (apiStateElapsed) {
        // check back in 1 second to see if we are still busy; if so we can show spinner
        setTimeout(() => {
            //     console.log("setForceRerender: apiStateElapsed=" + apiStateElapsed + ", apiState=" + apiState)
            setForceRerender(state => !state);
        }, 1000);
    }

    const order = cart.getOrder();
    const showCartPanel = token && order && order.shoppingCart && order.shoppingCart.length > 0 && !cart.isCartSuppressed() && !window.location.href.includes("checkout");
    const menu: MenuItemData[] = getSessionStore("menu");
    const isSmallWindow = window.matchMedia("(max-width: " + iPhoneWidth + "px)").matches;
    const noCartPopup = window.matchMedia("(max-width: " + noCartPopupWidth + "px)").matches;

    return (
        <StyledMasterContainer $defaultFonts={app.themes.defaultFonts} $h1FontFamily={app.themes.h1FontFamily}
            $foreColor={app.themes.foreColor}>
            {(apiStateElapsed > 1000) && <Spinner />}
            {errorMsg ? (
                <StyledErrorText>
                    {errorMsg}
                </StyledErrorText>
            ) : (
                menu &&
                <React.Fragment>
                    <StyledBanner $foreColor={app.themes.backColor10} $backColor={app.themes.foreColor}>
                        {app.banner}
                    </StyledBanner>
                    <PageHeader logo={formatImageUrl(app.logoUrl, { graphicsSubfolder: "other" })} altLogo={app.companyName}
                        facebookUrl={app.facebookUrl} menu={menu} menuItemVisible={isVisible}
                        user={token && getCookie("company") ? getCookie("company") : null} showCartIcon={app.shoppingCart && !!token}
                        handleLogin={handleLogin} />
                    {!isSmallWindow &&
                        <NavBar menuItems={menu} mainOptions={{ height: 42, fontSize: 14 }} dropdownOptions={{ fontSize: 14 }} isVisibleCallback={isVisible} />
                    }

                    {cart.categoriesExist() &&
                        <ContentAndCart>
                            <StyledContentContainer>
                                {props.children}
                            </StyledContentContainer>
                            {app.shoppingCart && showCartPanel && !noCartPopup && (
                                <StyledCartContainer>
                                    <cart.CartPopup totalChanged={() => setForceRerender(state => !state)} />
                                </StyledCartContainer>)}
                        </ContentAndCart>
                    }
                </React.Fragment>
            )}
        </StyledMasterContainer>
    );
}
/*
id : string -- if there is more than one navbar this is required
menuItems : MenuItemData[]
height : overall height, defaults to 48
fontSize : defaults to 24
backgroundImage OR backColor : defaults to themes.foreColor
foreColor : defaults to themes.backColor10
hoverForeColor : defaults to themes.foreColor
hoverBackColor : defaults to themes.backColor10
dropdownBackColor : defaults to themes.backColor25
dropdownFontSize : defaults to fontSize
*/
// cartIcon={token ? <CartIcon /> : null}
/* props:
    themes
    logo : static graphic, must be png
    altLogo : string for alt tag
    logoWidth : int
    facebookUrl : link to Facebook page if any
    banner : string for banner message above logo
    user : if null "login" link is shown, else "welcome" user message is shown
    handleLogin : callback for "login" prompt clicked

    components:
    searchPopup : search icon that shows popup when hovered over and takes one prop : fontSize
    cartIcon : display cart icon with item count inside; handles mouseover to show full cart (no icon shown if null)

    /* to pass a prop to search:
    render in PageHeader should render searchPopup (or whatever) as follows:
    {React.cloneElement(props.searchPopup, { fontSize: 32 })}

*/

// page header holds logo, widgets (all stuff to right of logo) and navbar
// navbar is below logo and widgets on desktop; becomes hamburger at far right on mobile
// desktop: logo centered, widgets to right
// mobile: logo at left, widgets at right

const StyledWidgetsContainer = styled.div`
    @media screen and (max-width: ${widgetsBelowLogoWidth}px) {
        position: relative;
        width: 100%;
    }
`
const StyledPageHeader = styled.div<{ $backColor: string; $fontFamily: string; $foreColor: string }>`
    display: flex;
    width: 100%;
    position: relative;
    justify-content: center;        /* for logo */
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    background-color: ${props => props.$backColor};
    font-family: ${props => props.$fontFamily};
    color: ${props => props.$foreColor};
    @media screen and (max-width: ${iPadMaxWidth}px) {
        justify-content: flex-start;
    }
    @media screen and (max-width: ${widgetsBelowLogoWidth}px) {
        justify-content: center;        /* widgets will be under so logo can be centered */
        flex-direction: column;
    }
`
const StyledLogo = styled.img`
    max-width: 40%;
    height: auto;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: pointer;
`
// following holds everything except logo
const StyledWidgets = styled.div<{ $backColor: string }>`
    position: absolute;
    background-color: ${props => props.$backColor};
    right: 8px;
    top: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    @media screen and (max-width: ${widgetsBelowLogoWidth}px) {
        position: relative;
        right: 0;
    }
`
const StyledWidget = styled.div`
    margin-left: 4px;
    margin-right: 4px;
`
/*
const StyledLogoAndLaunch = styled.div`
    position: relative;
    width: 100%;
    max-width: 1200px;
    @media (max-width: ${iPadMinWidth}px) {
        text-align: left;
    }
    `
// holds social media icons, search icon, shopping cart icon
const StyledLaunchContainer = styled.div`
    position: absolute;
    right: 0;
    top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`
const StyledLaunchItem = styled.div<{ $cursor?: string; $hoverStyle?: string }>`
    margin-right: 12px;
    cursor: ${props => props.$cursor ? props.$cursor : "pointer"};
    :hover 
    {
        font-style: ${props => props.$hoverStyle ? props.$hoverStyle : "regular"};
    }
`
*/
const StyledLoginBox = styled.p`
     font-size: 14px;
     line-height: 16px;
     width: 200px;
     text-align: center;
     cursor: pointer;
     :hover 
     {
         font-style: "italic";
     }
`
const StyledIconButton = styled.div`
    font-size: 24px;
    margin-top: 4px;
`
interface PageHeaderProps {
    logo: string;
    menu: MenuItemData[];
    facebookUrl: string;
    user: string | null;
    altLogo: string;
    showCartIcon: boolean;
    menuItemVisible: (menuItem: MenuItemData) => boolean;
    handleLogin: () => void;
}
const PageHeader: React.FC<PageHeaderProps> = (props) => {
    const navigate = useNavigate();
    const cart = useShoppingCart();
    const handleLogoClick = () => {
        navigate("/");
    }
    const isSmallWindow = window.matchMedia("(max-width: " + iPhoneWidth + "px)").matches;
    return (
        <StyledPageHeader $fontFamily={app.themes.defaultFonts} $foreColor={app.themes.foreColor} $backColor={app.themes.backColor25}>
            <StyledLogo src={props.logo} onClick={handleLogoClick} />
            <StyledWidgetsContainer>
                <StyledWidgets $backColor={app.themes.backColor25}>
                    {!isSmallWindow &&
                        <StyledWidget>
                            <StyledIconButton>
                                <a href={props.facebookUrl}>
                                    <i className="fab fa-facebook-f" />
                                </a>
                            </StyledIconButton>
                        </StyledWidget>
                    }
                    <StyledWidget>
                        <SearchPopup />
                    </StyledWidget>
                    {(app.loginAllowed || props.user) &&
                        <StyledWidget>
                            <StyledLoginBox onClick={props.handleLogin}>{props.user ? ("WELCOME " + props.user) : "WHOLESALE CUSTOMER SIGN IN OR REGISTER"}</StyledLoginBox>
                        </StyledWidget>}
                    {props.showCartIcon && (
                        <StyledWidget>
                            <cart.CartIcon />
                        </StyledWidget>)
                    }
                    {isSmallWindow &&
                        <SlidingNavBar menuItems={props.menu}
                            socialMedia={SocialMedia} isVisibleCallback={props.menuItemVisible} />
                    }
                </StyledWidgets>
            </StyledWidgetsContainer>
        </StyledPageHeader>

    );
}
const SocialMediaGroup = styled.div<{ $justify: string; $backColor: string; $lineHeight: number; $padding: number }>`
    display: flex;
    justify-content: ${props => props.$justify};
    align-items: center;
    background-color: ${props => props.$backColor};
    line-height: ${props => props.$lineHeight}px;
    padding: ${props => props.$padding}px;
`
const SocialMediaItem = styled.div`
    margin-right: 12px;
    font-size: 24px;
`
interface SocialMediaProps {
    justify?: string;        // flex-start or center; default to flex-start
    backColor?: string;     // default to transparent
    lineHeight: number;
    padding: number;
}
export const SocialMedia: React.FC<SocialMediaProps> = (props) => {
    return (
        <SocialMediaGroup $justify={props.justify ? props.justify : "flex-start"} $backColor={props.backColor ? props.backColor : "transparent"} $lineHeight={props.lineHeight}
            $padding={props.padding}>
            <SocialMediaItem>
                <a href={app.facebookUrl} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square" /></a>
            </SocialMediaItem>
        </SocialMediaGroup>
    )
}

export default MasterPage;

