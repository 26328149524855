import money from 'money-math';

const mul = (x: number, y: number): number => {
    return parseFloat(money.mul(money.floatToAmount(x), money.floatToAmount(y)));
}
const div = (x: number, y: number): number => {
    return parseFloat(money.div(money.floatToAmount(x), money.floatToAmount(y)));
}
const add = (x: number, y: number): number => {
    return parseFloat(money.add(money.floatToAmount(x), money.floatToAmount(y)));
}
const subtract = (x: number, y: number): number => {
    return parseFloat(money.subtract(money.floatToAmount(x), money.floatToAmount(y)));
}
const percent = (x: number, y: number): number => {
    return parseFloat(money.percent(money.floatToAmount(x), money.floatToAmount(y)));
}

export default {
    mul,
    div,
    add,
    subtract,
    percent
}
