import React from 'react';
import styled from 'styled-components';
import { RgbaStringColorPicker } from "react-colorful";

import SamModal from '../SamModalV2';
import IconButton, { ButtonsRow } from '../IconButtonV2';

const width = 264;

const ColorPickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
`
const CurrentAndNewBarsAndText = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    p {
        margin: 0;
    }
`
const CurrentBarAndNewBars = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`
const CurrentOrNewBar = styled.div<{ $color: string }>`
    width: 50%;
    height: 20px;
    background-color: ${props => props.$color};
    border: 1px solid black;
`
const CurrentAndNewLabels = styled.div`
    display: flex;
    justify-content: space-around;
`
const colorToRgb: Record<string, Record<string, number>> = {
    white: { r: 0, g: 0, b: 0 },
    red: { r: 255, g: 0, b: 0 },
    lime: { r: 0, g: 255, b: 0 },
    blue: { r: 0, g: 0, b: 255 },
    yellow: { r: 255, g: 255, b: 0 },
    cyan: { r: 0, g: 255, b: 255 },
    aqua: { r: 0, g: 255, b: 255 },
    magenta: { r: 255, g: 0, b: 255 },
    fuchsia: { r: 255, g: 0, b: 255 },
    silver: { r: 192, g: 192, b: 192 },
    gray: { r: 128, g: 128, b: 128 },
    maroon: { r: 128, g: 0, b: 0 },
    olive: { r: 128, g: 128, b: 0 },
    green: { r: 0, g: 128, b: 0 },
    purple: { r: 128, g: 0, b: 128 },
    teal: { r: 0, g: 128, b: 128 },
    navy: { r: 0, g: 0, b: 128 }
};

export class CssColor {
    r: number;
    g: number;
    b: number;
    a: number;
    isValid: boolean;

    // create standard color object from string formatted as "rgb(..." or "#..." or "somecolor"
    // created object formats a channel as standard 0-1 (1=full opacity)
    constructor(color: string) {
        this.a = 1;     // default to full opacity
        this.isValid = true;
        this.r = this.g = this.b = 0;       // set to black
        if (typeof color === "string") {
            if (color in colorToRgb) {
                this.r = colorToRgb[color].r;
                this.g = colorToRgb[color].g;
                this.b = colorToRgb[color].b;
            } else if (color.startsWith("rgb")) {
                const parts = color.replace("rgba(", '').replace("rgb(", '').replace(')', '').replaceAll(' ', '').split(',');
                this.r = parseInt(parts[0]);
                this.g = parseInt(parts[1]);
                this.b = parseInt(parts[2]);
                if (parts.length === 4) {
                    this.a = parseFloat(parts[3]);
                }
            } else if (color.startsWith("#")) {
                if (color.length === 4) {
                    this.r = parseInt(color[1] + color[1], 16);
                    this.g = parseInt(color[2] + color[2], 16);
                    this.b = parseInt(color[3] + color[3], 16);
                } else if (color.length === 7) {
                    this.r = parseInt(color.substring(1, 3), 16);
                    this.g = parseInt(color.substring(3, 5), 16);
                    this.b = parseInt(color.substring(5, 7), 16);
                }
            }
        } else {
            // invalid input
            this.isValid = false;
        }
    }

    toRgbString = (): string => {
        return "rgba(" + this.r + ", " + this.g + ", " + this.b + ", " + this.a + ")";
    }

    toHexString = (): string => {
        return "#" + this._intToHex(this.r) + this._intToHex(this.g) + this._intToHex(this.b);
    }

    _intToHex = (int: number): string => {
        let hex = int.toString(16);
        return (hex.length === 1 ? '0' : '') + hex;
    }
}

// input any valid color string in style attribute and output it in rgb string format (including converting, e.g. "black" to "rgb(0,0,0)")
export const standardizeColorStyle = (color: string): string => {
    const cssColor = new CssColor(color);
    return cssColor.toRgbString();
}

interface ColorPickerProps {
    color: string;
    colorSubmitted: (color: CssColor | null) => void;     // always rgb with alpha channel; alpha is always 0-1; alpha of 1 is omitted
}
const ColorfulPicker: React.FC<ColorPickerProps> = (props) => {
    return (
        <SamModal minWidth={width} maxWidth={width}>
            <ColorfulPickerModal {...props} />
        </SamModal>
    )
}
const ColorfulPickerModal: React.FC<ColorPickerProps> = (props) => {
    const [selectedColor, setSelectedColor] = React.useState(new CssColor(props.color));

    // NOTE: react-color returns alpha as percent (0-100); CSS standard is fraction (0-1)
    const colorChanged = (color: string) => {
        console.log("colorChanged:", color);
        //    console.log("colorChanged:", color, "; setting selectedColor to:", CssColor.fromRGBColor(color.rgb));
        setSelectedColor(new CssColor(color));
    }

    //  console.log("selectedColor.toRgbString:", { selectedColor, rgb: selectedColor.toRgbString() });

    return (
        <ColorPickerContainer>
            <RgbaStringColorPicker color={selectedColor.toRgbString()} onChange={colorChanged} />
            <NumericInput initialColor={selectedColor} onChange={color => setSelectedColor(color)} />
            <CurrentAndNewBarsAndText>
                <CurrentBarAndNewBars>
                    <CurrentOrNewBar $color={selectedColor.toRgbString()} />
                    <CurrentOrNewBar $color={new CssColor(props.color).toRgbString()} />
                </CurrentBarAndNewBars>
                <CurrentAndNewLabels>
                    <p>New</p>
                    <p>Current</p>
                </CurrentAndNewLabels>
            </CurrentAndNewBarsAndText>
            <ButtonsRow height={30}>
                <IconButton caption="Use this color" onClick={() => props.colorSubmitted(selectedColor)} />
                <IconButton caption="Cancel" onClick={() => props.colorSubmitted(null)} />
            </ButtonsRow>
        </ColorPickerContainer>
    )
}
//--------------------------------------------------
const NumericInputContainer = styled.div`
    font-size: 11px;
    width: 98%;
    margin-top: 8px;
    margin-bottom: 8px;
    label {
        font-weight: bold;
    }
`
const HexInput = styled.input`
    margin-right: 8px;
    width: 50px;
`
const RGBInput = styled.input`
    margin-left: 2px;
    margin-right: 2px;
    width: 24px;
`
interface NumericInputProps {
    initialColor: CssColor;
    onChange: (color: CssColor) => void;
}
const NumericInput: React.FC<NumericInputProps> = (props) => {
    React.useEffect(() => {
        xRef.current.value = props.initialColor.toHexString().substring(1);
        rRef.current.value = props.initialColor.r + '';
        gRef.current.value = props.initialColor.g + '';
        bRef.current.value = props.initialColor.b + '';
        aRef.current.value = props.initialColor.a + '';
    }, [props.initialColor]);

    const xRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const rRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const gRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const bRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const aRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

    const inputBlurred = () => {
        props.onChange(new CssColor("rgba(" + rRef.current.value + "," + gRef.current.value + "," + bRef.current.value + "," + aRef.current.value + ")"));
    }
    const hexBlurred = () => {
        props.onChange(new CssColor("#" + xRef.current.value));
    }

    return (
        <NumericInputContainer>
            <label>Hex:</label>
            <HexInput ref={xRef} defaultValue={props.initialColor.toHexString().substring(1)} onBlur={hexBlurred} />
            <label>RGB:</label>
            <RGBInput ref={rRef} defaultValue={props.initialColor.r + ''} onBlur={inputBlurred} />
            <RGBInput ref={gRef} defaultValue={props.initialColor.g + ''} onBlur={inputBlurred} />
            <RGBInput ref={bRef} defaultValue={props.initialColor.b + ''} onBlur={inputBlurred} />
            <RGBInput ref={aRef} defaultValue={props.initialColor.a + ''} onBlur={inputBlurred} />
        </NumericInputContainer>
    )
}
export default ColorfulPicker;