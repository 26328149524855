import React from 'react';
import styled from 'styled-components';
import { usePaymentInputs } from 'react-payment-inputs';

import { deformat, StyledErrorText, iPhoneWidth } from '../libSupport';
import { useGlobalContext } from '../SamState';
import SamForm from './SamFormV5';
import IconButton, { ButtonsRow } from '../IconButtonV2';

import { CreditCardRecord, CreditCardType, CreditCardSummaryRecord, FormFieldRecord, FormFieldType, ImageFileOptions, FormFieldGetInputPropsType } from '../../interfaces/lib-api-interfaces';
import { formatImageUrl } from '../ImageFormatter';
import FormMgr from './FormMgr';

/* credit card usage:
    card details are stored on server and never passed to client
    upon login the server returns the card summary if there is a valid card stored
    if the user updates the card and requests that it be kept, it is passed back to server with order
    if user wants to use stored card, the summary is passed to server with order and server retrieves details for charging card
    credit card details and summary are stored in global context while on client
*/
const useCreditCards = () => {
  const { getContext, setContext } = useGlobalContext();

  const ccKey = "creditCard";
  const summaryKey = "ccSummary";

  const setCreditCard = (cc: CreditCardRecord) => {
    setContext(ccKey, cc);
    setCcSummary(loadCCSummary(cc));
  }
  const getCreditCard = (): CreditCardRecord | null => {
    return getContext(ccKey);
  }
  const getCcSummary = (): CreditCardSummaryRecord | null => {
    return getContext(summaryKey);
  }
  const setCcSummary = (summary: CreditCardSummaryRecord | null) => {
    setContext(summaryKey, summary);
  }
  // return empty string if no cc
  const getFormattedCcSummary = (): string => {
    const summary = getCcSummary();
    if (summary && summary.last4) {
      return summary.cardTypeDesc + " ending in " + summary.last4;
    }
    return '';
  }
  return {
    getCreditCard,
    setCreditCard,
    getCcSummary,
    setCcSummary,
    getFormattedCcSummary
  }
}
//------------ CC SUPPORT FUNCTIONS ----------------
const cardTypeList = [
  { caption: "", value: CreditCardType.none },
  { caption: "Amex", value: CreditCardType.amex },
  { caption: "Visa", value: CreditCardType.visa },
  { caption: "MasterCard", value: CreditCardType.masterCard },
  { caption: "Discover", value: CreditCardType.discover }
];
const getCardTypeDesc = (type: CreditCardType): string => {
  const found = cardTypeList.find(entry => entry.value === type);
  return found ? found.caption : '';
}
export const formatCardNo = (ccSummary: CreditCardSummaryRecord | null): string => {
  if (!ccSummary || !ccSummary.card_type || !ccSummary.last4) {
    return "Call for number";
  }
  const length = ccSummary.card_type === CreditCardType.amex ? 9 : 12;
  return ("x".repeat(length) + ccSummary.last4);
}
export const loadCCSummary = (cc: CreditCardRecord): CreditCardSummaryRecord => {
  if (!cc.cardNumber) {
    return { last4: '', card_type: CreditCardType.none, cardTypeDesc: '' };
  }
  return { last4: cc.cardNumber.slice(-4), card_type: cc.cardNumber[0] as CreditCardType, cardTypeDesc: getCardTypeDesc(cc.cardNumber[0] as CreditCardType) };
}
// take cc object as passed to handler callback and package it into a string for sending to server (no encryption)
export const packageCC = (cc: CreditCardRecord): string => {
  return cc.cardNumber + "\t" + cc.expiryDate + "\t" + cc.cvc + "\t" + cc.zip;
}


//------------ COMPONENTS ----------------
/*
// holds button and label
const CreditCardRadioButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
const RadioButton = styled.input`
    transform: scale(1.5);
    margin-right: 8px;
    margin-left: 8px;
`
const PaypalLogoImg = styled.img`
    height: 30px;
    width: auto;
    margin-top: 16px;
    margin-bottom: 16px;
`
interface ChooseCCOrPaypalProps {
    onSubmit: (selection: string) => void;      // "cc" or "paypal"
}
const ChooseCCOrPaypal: React.FC<ChooseCCOrPaypalProps> = (props) => {
    return (
        <CreditCardRadioButtonContainer>
            <RadioButton type="radio" id="paypal" checked={!!props.forms.getValue(props.id, "usePaypal")} onChange={props.radioChanged} />
            <PaypalLogoImg src={paypalLogo} />
        </CreditCardRadioButtonContainer>

    )
}
*/
export const editorWidth = Math.min(360, window.innerWidth);     // minimum so "CVV code" fits on one line; for cell phones it can take 2 lines

const CardIcons = styled.div`
    display: flex;
    height: 32px;
    margin-left: 24px;
    justify-content: flex-start;
    align-items: center;
`
const CreditCardIcons: React.FC = () => {
  const fileOptions: ImageFileOptions = { graphicsSubfolder: "other" };
  return (
    <CardIcons>
      <CreditCardLogo src={formatImageUrl("visa.png", fileOptions)} />
      <CreditCardLogo src={formatImageUrl("mastercard.png", fileOptions)} />
      <CreditCardLogo src={formatImageUrl("amex.png", fileOptions)} />
      <CreditCardLogo src={formatImageUrl("discover.png", fileOptions)} />
    </CardIcons>
  )
}
const CreditCardShadedArea = styled.div`
    background-color: lightgray;
    width: ${editorWidth}px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    padding: 8px;
    margin: 0 auto 16px auto;
    @media screen and (max-width: ${iPhoneWidth}px) {
        margin: 0;
        margin-bottom: 16px;
        padding: 0;
    }
`
const CreditCardLogo = styled.img`
    height: 24px;
    width: auto;
    margin-left: 8px;
`
const CreditCardInputForm = styled.div`
    font-size: 14px;
    border: 1px solid;
    margin-left: 18px;
    margin-right: 18px;
    background-color: white;
`
export interface CreditCardEditorProps {
  submitCaption: string;  // "Place order with this card" or just "Use this card"
  cancelCaption?: string;     // if omitted the cancel button is not rendered
  onSubmit: (cc: CreditCardRecord | null) => void;        // pass null to cancel; use loadCCSummary to convert to summary format
}
export const CreditCardEditor: React.FC<CreditCardEditorProps> = (props) => {
  const [errorMsg, setErrorMsg] = React.useState<string>();
  const [cardSubmitted, setCardSubmitted] = React.useState(false);

  const { setContext } = useGlobalContext();

  const cc = useCreditCards();
  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getZIPProps,
  } = usePaymentInputs();

  const id = "creditCard";

  React.useEffect(() => {
    if (cardSubmitted) {
      const forms = new FormMgr(setContext);
      setCardSubmitted(false);
      if (forms.validateForm(id, validator)) {
        const values = { ...forms.getFormValues(id) };      // get a copy so we can modify it
        values.cardNumber = deformat(values.cardNumber);
        values.expiryDate = deformat(values.expiryDate);
        //             console.log("submitting:", values)
        cc.setCreditCard(values);
        props.onSubmit(values);
      }
    }
  }, [cardSubmitted]);

  const validator = (fieldName: string, inputs: Record<string, any>): string => {
    if (meta.erroredInputs[fieldName]) {
      return meta.erroredInputs[fieldName];
    }
    if (!inputs[fieldName]) {
      return "Entry is required";
    }
    /*
    if (fieldName === "cardNumber" && !isCardNoValid(val as string)) {
      return "Card number is not valid";
    } else if ((fieldName === "expYear" || fieldName === "expMonth") && val === "0") {
      return "Field is required";
    } else if ((fieldName === "cvv" || fieldName === "zip") && !isAllNumeric(deformat(val))) {
      return "Field must be digits only";
    }
    */
    return '';
  }

  const getPaymentInputProps: FormFieldGetInputPropsType = (fieldName: string, options: Record<string, any>) => {
    if (fieldName === "cardNumber") {
      return getCardNumberProps(options);
    } else if (fieldName === "expiryDate") {
      return getExpiryDateProps(options);
    } else if (fieldName === "cvc") {
      return getCVCProps(options);
    } else if (fieldName === "zip") {
      return getZIPProps(options);
    } else {
      throw "getPaymentInputProps: invalid field name " + fieldName;
    }
  }

  const ccFormFields: FormFieldRecord[] = [
    // NOTE: field names are set by react-payments-input and should not be changed
    { name: "cardNumber", label: "Card #", type: FormFieldType.reactPaymentInputs, width: 97, size: { marginLeft: 4 } },
    { name: "expiryDate", label: "Exp date", type: FormFieldType.reactPaymentInputs, width: 28, size: { marginLeft: 4, marginRight: 8 } },
    { name: "cvc", label: "CVV code", type: FormFieldType.reactPaymentInputs, width: 28, size: { marginLeft: 2, marginRight: 2 } },
    { name: "zip", label: "Billing zip", type: FormFieldType.reactPaymentInputs, width: 28, size: { marginLeft: 2, marginRight: 8 } }
  ];

  /*
  const isCardNoValid = (cardNo: string): boolean => {
    cardNo = deformat(cardNo);
    if (cardNo.length > 16 || cardNo.length < 13 || !cardNo.match(/^[0-9]*$/)) {
      return false;
    }
    let sum = 0;
    let mul = 1;
    let len = cardNo.length;
    try {
      for (let i = 0; i < len; i++) {
        let digit = cardNo.substring(len - i - 1, len - i);
        let tproduct = parseInt(digit) * mul;
        if (tproduct >= 10)
          sum += (tproduct % 10) + 1;
        else
          sum += tproduct;
        if (mul === 1)
          mul++;
        else
          mul--;
      }
      return ((sum % 10) === 0);
    }
    catch
    {
      return false;
    }
  }
*/
  const buttonStyle = { fontWeight: "bold", margin: "8px", height: "40px", fontSize: "14px", minWidth: "100px" } as Record<string, string>;
  if (props.cancelCaption) {
    buttonStyle.width = "40%";
  }
  return (
    <CreditCardShadedArea>
      <CreditCardIcons />
      <CreditCardInputForm>
        {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
        <SamForm id={id} fields={ccFormFields} initialValues={cc.getCreditCard()} getPaymentInputProps={getPaymentInputProps} />
        <ButtonsRow>
          <IconButton id="use" style={buttonStyle} caption={props.submitCaption} icon="fa fa-credit-card" onClick={() => setCardSubmitted(true)} />
          {props.cancelCaption && <IconButton id="cancel" style={buttonStyle} caption={props.cancelCaption} icon="far fa-window-close" onClick={() => props.onSubmit(null)} />}
        </ButtonsRow>
      </CreditCardInputForm>
    </CreditCardShadedArea>
  )
}
export default useCreditCards;
