import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import MasterPage from '../libs/MasterPage';

const StyledContainer = styled.div`
    max-width: 500px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    p {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        color: black;
        margin-top: 0;
        margin-bottom: 8px;
    }
    h2 {
        font-size: 16px;
    }
    h3 {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: bold;
    }
`
const StyledSizeChart = styled.table`
    width: 240px;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
`
const StyledChartHeader = styled.div<{ hdrWidth: number; border?: string }>`
    width: ${props => props.hdrWidth}px;
    border-bottom: ${props => props.border};
    text-align: center;
`
const LinkStyle = styled.span`
    text-decoration: underline;
    cursor: pointer;
`

const Repair: React.FC = () => {
    const border = "thin solid";
    return (
        <MasterPage>
            <StyledContainer>
                <h1>Jacob's Musical Chimes Repair Info</h1>
                <p style={{marginTop: "32px"}}>To all our valued lovers of Jacob’s beautiful traditional wind chimes:</p>
                <p>After 40 years of making his magical chimes, Jacob is no longer able to perform rehabilitation and repairs on his chimes.</p>
                <p>To help with parts for possible home repair, email images of your chime to: info@jacobschimes.com.</p>
                <p>With our gratitude,<br />
                The Jacob's Musical Chimes Team</p>
                <h2>Free center cable repair kits are still available:</h2>
                <p>
                    If all the pipes on your chime are still hanging and the problem is only a
                    broken center cable, we offer a free and easy repair kit to get you up and
                    chiming again. To order a repair kit, call us at 1-800-627-5840 or <Link to="/contact"><LinkStyle>send us a message</LinkStyle></Link> and
                    request a kit. You will need to know which model
                    (Waterfall, Cathedral, etc.) you own. To identify your chime, count the total
                    number of pipes and measure the longest pipe. Then use this chart:
                </p>

                <StyledSizeChart>
                    <tbody>
                        <tr>
                            <td><StyledChartHeader hdrWidth={120}>&nbsp;</StyledChartHeader></td>
                            <td><StyledChartHeader hdrWidth={50} border={border}>Pipes</StyledChartHeader></td>
                            <td><StyledChartHeader hdrWidth={150} border={border}>Longest pipe</StyledChartHeader></td>
                        </tr>
                        <tr>
                            <td>Echo</td>
                            <td>3</td>
                            <td>17"</td>
                        </tr>
                        <tr>
                            <td>Whisper</td>
                            <td>7</td>
                            <td>7"</td>
                        </tr>
                        <tr>
                            <td>Cascade</td>
                            <td>7</td>
                            <td>11-1/2"</td>
                        </tr>
                        <tr>
                            <td>Waterfall</td>
                            <td>9</td>
                            <td>12-1/2"</td>
                        </tr>
                        <tr>
                            <td>Cathedral</td>
                            <td>9</td>
                            <td>17"</td>
                        </tr>
                        <tr>
                            <td>Grandfather</td>
                            <td>7</td>
                            <td>25"</td>
                        </tr>
                    </tbody>
                </StyledSizeChart>
                <p>Do you need a new "Clapper" or "Windcatcher" with your repair kit? The Clapper
                    is the disk that normally sits flat in the middle of your chime and strikes the
                    pipes. The Windcatcher is the larger disk that normally hangs at the bottom of
                    the chime. If you are missing either or both of these disks, ask for
                    replacements &mdash; they're free!
                </p>
                <p>
                    The Center Cable Repair kit comes with everything you need to restore your
                    center cable assembly to brand new, including cable, crimps and instructions.
                    The only tool you will need is a pair of pliers. If you order a repair kit <Link to="/contact"><LinkStyle>online</LinkStyle></Link>, be sure to mention which model
                    windchime you have, whether you need one or both disks, and your mailing
                    address.
                </p>
            </StyledContainer>
        </MasterPage>
    )
}

export default Repair;
