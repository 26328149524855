import React from 'react';
import styled from 'styled-components';

import { useTokens, useBookmarks } from '../SamState';
import MasterPage from '../MasterPage';
import IconButton from '../IconButtonV2';
import { OrderBillAddressEditor } from './OrderEditors';
import { genericApiError, usePostApi } from '../useDataApiV2';
import useShoppingCart, { OrderTerms, OrderStatus } from './useShoppingCart';
import DatePicker from '../forms/DatePicker';
import { renderAddressSummary, AddressSchemaType } from '../forms/AddressEditor';
import useCreditCards, { formatCardNo, CreditCardEditor, packageCC, loadCCSummary } from '../forms/CreditCardsV2';
import { AddressBookEditor } from '../useAddressBook';
import SamModal, { ModalMessageBox, ModalMessageBoxProps, ModalMessageResponseFlags, SamModalProps } from '../SamModalV2';
import { StyledErrorText } from '../libSupport';

import { AddressRecord, CreditCardSummaryRecord, AddressType, CouponStatus, CreditCardRecord } from '../../interfaces/lib-api-interfaces';
import { WSCustOrderInfoRecord, WSCouponRecord } from '../../interfaces/ws-api-interfaces';
import moneyCalc from '../api-shared/money-calc';

import app from '../../appData';
import api from '../../api-url';
import { useNavigate } from 'react-router-dom';

//---------------------------------------------------------------
const panelWidth = 310;
const checkoutWidth = 2 * panelWidth + 40;
const stackedCartWidth = 1048;      // place cart above forms
const stackedFormsWidth = 678;    // stack forms vertically

/* raw data is in order, orddtl, etc., loaded when customer logs in:
    shipTos contains array of all ship-to addresses on file (could be empty) in case cust wants to choose one
    order contains pending order (initialized by api if no pending order existed) including billing info and cc info
    orddtl contains pending order detail rows or empty array
    categories contains one record with 6 fields of category info (prices and free display qty)
*/
const StyledMasterContainer = styled.div`
    @media (max-width: ${stackedFormsWidth}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`
const StyledFormsContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: ${stackedFormsWidth}px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
`
const CartPopupContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-left: 16px;
`
//------ 3 boxes left hand side for addresses and payment info
const StyledAddressesAndPayment = styled.div`
    width: ${panelWidth}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 8px;
    margin-right: 16px;
`
// "Billing Address", "Payment Info", etc. with modify button on the right
const StyledHeaderDiv = styled.div`
    display: flex;
    width: 100%;
    height: 24px;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
`
const StyledSectionHeader = styled.p<{ $textAlign: string; $width?: string }>`
    font-size: 18px;
    font-weight: bold;
    text-align: ${props => props.$textAlign};       /* edit button is to the right */
    width: ${props => props.$width};             /* 100% if textAlign center, else null */
    margin: 0 8px 0 0;
`
// ship and bill address text
const StyledAddress = styled.div<{ $backColor: string }>`
    background-color: ${props => props.$backColor};
    text-align: left;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 8px;
    padding: 8px;
    p {
        margin: 0;
    }
`
// payment info section
const StyledOrderInfoLabelWithInput = styled.div<{ $marginBottom?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    height: 44px;
    margin-bottom: ${props => props.$marginBottom}px;
`
const StyledOrderInfoLabel = styled.p<{ $marginBottom?: number }>`
    margin-right: 4px;
    font-size: 12px;
    font-weight: bold;
    text-align: right;
    margin-bottom: ${props => props.$marginBottom}px;
`
const CCNoLabel = styled.p`
    text-align: left;
    font-size: 14px;
    margin: 0;
`
//----------------------------
//------ Order summary grid, order total, ship date, comments, submit buttons
const StyledOrderSummary = styled.div`
    width: ${panelWidth}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: 16px;
    margin-right: 8px;
`
const StyledOrderSummaryContainer = styled.div<{ $backColor: string }>`
    background-color: ${props => props.$backColor};
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
    font-size: 12px;
    padding: 8px;
`
const StyledSummaryLine = styled.p`
    margin: 2px;
`
// holds 2 submit buttons
const SubmitButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 8px;
`
const StyledTotalsBox = styled.div`
    text-align: center;
    margin-bottom: 16px;
    margin-top: 0;
    p {
        margin: 4px;
    }
`
const StyledCommentBox = styled.textarea<{ $backColor: string }>`
    width: 100%;
    height: 60px;
    background-color: ${props => props.$backColor};
`
const CouponRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
`
const CouponInput = styled.input<{ $backColor: string }>`
    background-color: ${props => props.$backColor};
    width: 120px;
`
const Checkout: React.FC = () => {
    const { getToken } = useTokens();
    const { post, isPostLoading } = usePostApi();
    const { navigateToBookmark } = useBookmarks();

    //    const [isEditingCC, setIsEditingCC] = React.useState<boolean>(false);
    //    const [isEditingBillAddress, setIsEditingBillAddress] = React.useState<boolean>(false);
    const [editingAddressBook, setEditingAddressBook] = React.useState<boolean>(false);
    const [couponCode, setCouponCode] = React.useState<string>();
    const [poNum, setPoNum] = React.useState<string>();
    const [couponMsg, setCouponMsg] = React.useState<string>();
    const [couponErrorMsg, setCouponErrorMsg] = React.useState<string>();
    const [modalProps, setModalProps] = React.useState<SamModalProps>();
    const [messageProps, setMessageProps] = React.useState<ModalMessageBoxProps>();
    const [forceRerender, setForceRerender] = React.useState(false);

    interface SaveOrderRecord {
        status: OrderStatus, successCallback: (result: WSCustOrderInfoRecord) => void
    }
    const [saveOrderRecord, setSaveOrderRecord] = React.useState<SaveOrderRecord>();

    const commentRef = React.useRef<HTMLTextAreaElement>() as React.MutableRefObject<HTMLTextAreaElement>;

    const cart = useShoppingCart();
    const cc = useCreditCards();
    const order = cart.getOrder();
    const navigate = useNavigate();

    const token = getToken();

    React.useEffect(() => {
        setCouponCode(order.coupon_code);
        setPoNum(order.po_num)
    }, []);

    const couponCodeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCouponCode(e.target.value);
    }
    const poNumChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPoNum(e.target.value);
    }
    const couponKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            applyCoupon();
        }
    }
    const couponSubmitted = (result: WSCouponRecord) => {
        if (result.status !== CouponStatus.valid) {
            setCouponErrorMsg(result.status === CouponStatus.expired ? "Coupon expired" : "Invalid coupon code");
        } else {
            cart.applyCoupon(result);
            setCouponMsg("Coupon applied");
            setTimeout(() => setCouponMsg(undefined), 3000);
        }
    }
    const applyCoupon = () => {
        setCouponErrorMsg(undefined);
        post(api.getCouponValue, { coupon: couponCode }, couponSubmitted, () => setCouponErrorMsg(genericApiError), getToken()!.token);
    }
    /*
        React.useEffect(() => {
            if (postShipTos) {
                const newShipTos = getContext("order").shipTos;
                if (!deepEqual(newShipTos, orgShipTos)) {
                    console.log("posting new shiptos"); console.log(newShipTos);
                    post(postShipToUrl, { custId: token.custId, shipTos: newShipTos }, null, null, token.token);
                }
                setIsEditingShipAddress(false);
                setPostShipTos(false);
            }
        }, [postShipTos]);
        */

    //-------------------- FINISH, POST, CANCEL ORDER -------------------------------
    // following are handlers for order info box buttons
    const handleSubmitOrder = () => {
        const order = cart.getOrder();
        if (order.net_tot < 99) {
            minimumNotMet(order.net_tot);
        } else {
            saveOrder(OrderStatus.pendingDownload, postOrderSucceeded);
        }
    }
    React.useEffect(() => {
        if (saveOrderRecord) {
            const order = cart.getOrder();
            order.status = saveOrderRecord.status;
            order.po_num = poNum;
            const ccPlain = cc.getCreditCard();
            if (order.terms === OrderTerms.callForCard) {
                order.terms = OrderTerms.creditCard;
                order.cc_summary = null;
            } else if (ccPlain) {
                order.cc_plain = packageCC(ccPlain);
            }
            if (commentRef.current.value) {
                order.comment = commentRef.current.value;
                cart.setOrder(order);
            }
            post(api.submitOrder, order, saveOrderRecord.successCallback, postFailed, getToken()!.token);
            setSaveOrderRecord(undefined);
        }
    }, [saveOrderRecord]);

    const handleSaveOrder = () => {
        setSaveOrderRecord({ status: OrderStatus.working, successCallback: saveOrderSucceeded });
    }
    const saveOrder = (status: OrderStatus, successCallback: (result: WSCustOrderInfoRecord) => void) => {
        setSaveOrderRecord({ status, successCallback });
    }

    //---------- POST HANDLING: SUBMIT (AFTER CC CONFIRMED), SAVE OR CANCEL ----------------------
    const postFailed = () => {
        setMessageProps({
            caption: "We're so sorry. We are having trouble accessing our server. Please try again later or call us at 800-627-5840.",
            responseFlags: ModalMessageResponseFlags.continue, onSubmit: modalMessageButtonClicked
        });
    }
    const postOrderSucceeded = (result: WSCustOrderInfoRecord) => {
        cart.setOrderSubmitted();
        cart.recalcCart(result.order, cart.getCategories());
        //    console.log("invoking modal");
        setMessageProps({
            caption: "Thank you for supporting Jacob's Musical Chimes! Your order has been submitted. You will receive a confirming email shortly, and another email when it ships.",
            responseFlags: ModalMessageResponseFlags.home, onSubmit: modalMessageButtonClicked
        });
    }
    const saveOrderSucceeded = () => {
        // if modalId is orderSaved, modalButtonClicked looks at id passed to event to determine what user wants
        setMessageProps({
            caption: "Your order has been saved. You can finish it now or log in again at any time to complete it.",
            responseFlags: ModalMessageResponseFlags.continueShopping, onSubmit: modalMessageButtonClicked
        });
    }
    const minimumNotMet = (netTot: number) => {
        setMessageProps({
            caption: "Our minimum order is $99.00 and you have $" + netTot.toFixed(2) + "; please keep shopping",
            responseFlags: ModalMessageResponseFlags.continueShopping, onSubmit: modalMessageButtonClicked
        });
    }
    //----------------------------------------------------------

    const handleContinue = () => {
        navigateToBookmark();
    }

    // modal invoked after order info box button is clicked
    // displayed after order has been saved, canceled or submitted
    const modalMessageButtonClicked = (response: ModalMessageResponseFlags) => {
        setMessageProps(undefined);
        if (response === ModalMessageResponseFlags.continue || response === ModalMessageResponseFlags.continueShopping) {
            navigateToBookmark();
        } else if (response === ModalMessageResponseFlags.home) {
            navigate("/");
        }
    }
    //-------------------- END FINISH, POST, CANCEL ORDER -------------------------------
    interface AddressBoxProps {
        address: AddressRecord;
    }
    const AddressBox: React.FC<AddressBoxProps> = (props) => {
        return (
            <StyledAddress $backColor={app.themes.backColor10}>
                {renderAddressSummary(props.address)}
                <p>{props.address.email}</p>
            </StyledAddress>
        );
    }

    const OrderSummaryBox: React.FC = () => {
        const cart = useShoppingCart();
        const categories = cart.getCategories();
        return (
            <StyledOrderSummaryContainer $backColor={app.themes.backColor10}>
                {Object.keys(categories).map(category => {
                    return (
                        categories[category].qty > 0 &&
                        <StyledSummaryLine key={category}>
                            {categories[category].qty}&nbsp;{categories[category].short_desc}&nbsp;@&nbsp;${(categories[category].ext / categories[category].qty).toFixed(2)}&nbsp;
                            =&nbsp;${categories[category].ext.toFixed(2)}
                        </StyledSummaryLine>
                    )
                })}
            </StyledOrderSummaryContainer>
        );
    }

    const handleTermsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        cart.setOrderTerms(e.target.value as OrderTerms);
    }
    const handleShipDateChange = (date: string) => {
        cart.setOrderShipDate(date);
    }
    const handleCCSubmit = (values: CreditCardRecord | null) => {
        if (values) {
            cart.setCCSummary(loadCCSummary(values));
        }
        setModalProps(undefined);
    }
    // id is -1 to cancel
    const handleShipAddressSubmit = (addressId: number) => {
        if (addressId >= 0) {
            cart.setOrderShipAddress(addressId);
        }
        setEditingAddressBook(false);
    }
    const editCreditCard = () => {
        setModalProps({
            component: CreditCardEditor as React.FC,
            componentProps: { submitCaption: "Use this card", cancelCaption: "Never mind", onSubmit: handleCCSubmit }
        });
    }
    const editBillAddress = () => {
        setModalProps({ component: OrderBillAddressEditor as React.FC, componentProps: { handleExit: () => setModalProps(undefined) } });
    }

    // props must include handleClick
    interface EditIconProps {
        handleClick: () => void;
    }
    const EditIcon: React.FC<EditIconProps> = (props) => {
        return (
            <i className="fas fa-edit" style={{ cursor: "pointer" }} onClick={props.handleClick} />
        );
    }

    if (!order) {
        return null;
    }
    const discountTot = order.disc_pct ? moneyCalc.percent(order.disc_pct, order.net_tot) : 0;
    const invTot = moneyCalc.subtract(order.net_tot, discountTot);
    const stackCart = window.matchMedia("(max-width: " + stackedCartWidth + "px)").matches;
    //   const buttonStyle = { border: "1px solid" };
    //   console.log("order:", order);
    return (
        <MasterPage>
            <h1>Review Order and Check Out</h1>
            <StyledMasterContainer>
                {stackCart &&
                    <CartPopupContainer>
                        <cart.CartPopup totalChanged={() => setForceRerender(state => !state)} />
                    </CartPopupContainer>
                }
                <StyledFormsContainer>
                    <StyledAddressesAndPayment>
                        <StyledHeaderDiv>
                            <StyledSectionHeader $textAlign="left">Billing</StyledSectionHeader>
                            <EditIcon handleClick={editBillAddress} />
                        </StyledHeaderDiv>
                        <AddressBox address={order.bill_address} />

                        <StyledHeaderDiv>
                            <StyledSectionHeader $textAlign="left">Shipping</StyledSectionHeader>
                        </StyledHeaderDiv>
                        <IconButton style={{ marginTop: "4px" }} caption="Choose different or edit addresses"
                            icon="fas fa-edit" onClick={() => setEditingAddressBook(true)} />
                        <AddressBox address={cart.getShipAddress()} />

                        <StyledHeaderDiv>
                            <StyledSectionHeader $textAlign="left">Payment</StyledSectionHeader>
                            <select style={{ width: "220px" }} onChange={handleTermsChange} value={order.terms}>
                                <option value={OrderTerms.creditCard}>Credit card</option>
                                <option value={OrderTerms.callForCard}>Call for credit card</option>
                                <option value={OrderTerms.proForma}>Proforma</option>
                                <option value={OrderTerms.net30}>Net 30 (with approved credit)</option>
                            </select>
                        </StyledHeaderDiv>
                        {order.terms === OrderTerms.creditCard &&
                            <StyledHeaderDiv>
                                <CCNoLabel>Card no. {formatCardNo(order.cc_summary)}</CCNoLabel>
                                <EditIcon handleClick={editCreditCard} />
                            </StyledHeaderDiv>
                        }
                    </StyledAddressesAndPayment>

                    <StyledOrderSummary>
                        <StyledHeaderDiv>
                            <StyledSectionHeader $textAlign="center" $width="100%">Order Summary</StyledSectionHeader>
                        </StyledHeaderDiv>
                        <OrderSummaryBox />
                        {discountTot ? (
                            <StyledTotalsBox>
                                <p>Net Total ${order.net_tot.toFixed(2)}</p>
                                <p>Less discount: -${discountTot.toFixed(2)}</p>
                                <p style={{ fontWeight: "bold" }}>Invoice amount: ${invTot.toFixed(2)}</p>
                            </StyledTotalsBox>
                        ) : (
                            <StyledTotalsBox>
                                <p>Order total: ${order.net_tot.toFixed(2)}</p>
                            </StyledTotalsBox>
                        )}
                        <CouponRow>
                            <StyledOrderInfoLabel>Coupon:</StyledOrderInfoLabel>
                            <CouponInput $backColor={app.themes.backColor10} onKeyPress={couponKeyPressed} onChange={couponCodeChanged} defaultValue={couponCode} />
                            <IconButton style={{ marginLeft: "8px" }} isDisabled={!!order.coupon_code} caption="Apply" onClick={applyCoupon} />
                        </CouponRow>
                        {couponMsg && <StyledErrorText>{couponMsg}</StyledErrorText>}
                        {couponErrorMsg && <StyledErrorText>{couponErrorMsg}</StyledErrorText>}
                        <CouponRow>
                            <StyledOrderInfoLabel>P.O. number:</StyledOrderInfoLabel>
                            <CouponInput $backColor={app.themes.backColor10} onChange={poNumChanged} defaultValue={poNum} />
                        </CouponRow>
                        <StyledOrderInfoLabelWithInput>
                            <StyledOrderInfoLabel>Date wanted:</StyledOrderInfoLabel>
                            <DatePicker name="datepicker" value={order.req_date} onChange={handleShipDateChange} />
                        </StyledOrderInfoLabelWithInput>
                        <StyledOrderInfoLabel $marginBottom={4}>
                            Comments (up to 500 characters):
                        </StyledOrderInfoLabel>
                        <StyledCommentBox ref={commentRef} $backColor={app.themes.backColor10} maxLength={500} />

                        <SubmitButtonContainer>
                            <IconButton style={{ width: "42%" }} caption="Save for later" onClick={handleSaveOrder} />
                            <IconButton style={{ width: "56%" }} caption="Continue shopping" onClick={handleContinue} />
                        </SubmitButtonContainer>
                        <IconButton caption="Place order" onClick={handleSubmitOrder}
                            style={{ width: "100%", height: "40px", fontSize: "20px", fontWeight: "bold", marginTop: "8px" }} icon="fas fa-check" />
                    </StyledOrderSummary>
                    {!stackCart &&
                        <CartPopupContainer>
                            <cart.CartPopup totalChanged={() => setForceRerender(state => !state)} />
                        </CartPopupContainer>
                    }
                </StyledFormsContainer>
            </StyledMasterContainer>
            {editingAddressBook && <AddressBookEditor type={AddressType.ship} schemaType={AddressSchemaType.wholesale} showUseButton={true} showAsModal={true} showTitle={false}
                onSubmit={handleShipAddressSubmit} />}
            {modalProps && <SamModal {...modalProps} />}
            {messageProps && <ModalMessageBox {...messageProps} />}
        </MasterPage >
    );
}
// onSubmit={modalButtonClicked}
export default Checkout;

/*
    const WrappedBillAddressEditor = wrapModal(OrderBillAddressEditor as React.FC) as unknown as React.FC<EditorProps>;
    const WrappedCreditCardEditor = wrapModal(CreditCardEditor as React.FC) as unknown as React.FC<CreditCardEditorProps>;
            {isEditingBillAddress && <WrappedBillAddressEditor handleExit={() => setIsEditingBillAddress(false)} />}
            {isEditingCC && <WrappedCreditCardEditor submitCaption="Use this card" onSubmit={handleCCSubmit} />}
*/