import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { miniLayoutThreshhold } from '../libs/libSupport';

import MasterPage from '../libs/MasterPage';

import app from '../appData';
import { useSessionStore } from '../libs/SamState';
import { MenuItemData } from '../interfaces/lib-api-interfaces';
import InfoContent from '../libs/InfoContent';
import { formatImageUrl } from '../libs/ImageFormatter';

const HomeImage = styled.img` 
    width: 100%;
    height: auto;
    margin-bottom: 8px;
`
const StyledFooterContainer = styled.div<{ backColor: string }>`
    background-color: ${props => props.backColor};
    display: flex;
    justify-content: space-between;
    margin: 16px auto 0 auto;
    max-width: 1000px;
    flex-wrap: wrap;
`
const StyledFooterSection = styled.div<{ marginLeft?: number; marginRight?: number; textAlign: string; lineHeight: number }>`
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 12px;
    margin-left: ${props => props.marginLeft}px;
    margin-right: ${props => props.marginRight}px;
    text-align: ${props => props.textAlign};
    line-height: ${props => props.lineHeight}px;
    font-size: 14px;
    @media (max-width: ${miniLayoutThreshhold}px) {
        font-size: 12px;
    }
`
const StyledSectionHeader = styled.p`
    font-weight: bold;
    margin-bottom: 4px;
    line-height: 12px;
`
const InfoDiv = styled.div`
    margin-top: 8px;
`
const menuLineHeight = 12;
const menuAlign = "left";
const infoLineHeight = 18;
const infoAlign = "center";
const indent = 80;

const Home: React.FC = () => {
    const [rerender, setRerender] = React.useState(false);

    const { getSessionStore } = useSessionStore();

    return (
        <MasterPage menuLoaded={() => setRerender(!rerender)}>
            {getSessionStore("menu") &&
                <React.Fragment>
                    <Link to="/products/silhouettes"><HomeImage src={formatImageUrl("silhouettes_sky.jpg", { graphicsSubfolder: "other" })} 
                        alt="Jacob's Silhouette chimes" /></Link>
                    <InfoContent url={'about-jmc'} maxWidth={900} />
                    <StyledFooterContainer backColor={app.themes.backColor25}>
                        <StyledFooterSection textAlign={menuAlign} lineHeight={menuLineHeight} marginLeft={indent}>
                            <StyledSectionHeader>Categories</StyledSectionHeader>
                            <div>
                                {getSessionStore("menu")[0].popupItems!.map((mapEntry: MenuItemData) => {
                                    return (
                                        <Link to={mapEntry.href!} key={mapEntry.caption}><p>{mapEntry.caption}</p></Link>
                                    );
                                })}
                            </div>
                        </StyledFooterSection>
                        <StyledFooterSection textAlign={menuAlign} lineHeight={menuLineHeight}>
                            <StyledSectionHeader>Navigate</StyledSectionHeader>
                            <div>
                                {app.siteMap.map((mapEntry, index) => {
                                    return (
                                        mapEntry.href!.startsWith("http") ? (
                                            <a href={mapEntry.href!} key={index}><p>{mapEntry.caption}</p></a>
                                        ) : (
                                            <Link to={mapEntry.href!} key={index}><p>{mapEntry.caption}</p></Link>
                                        )
                                    );
                                })}
                            </div>
                        </StyledFooterSection>
                        <StyledFooterSection textAlign={infoAlign} lineHeight={infoLineHeight} marginRight={indent}>
                            <StyledSectionHeader>Info</StyledSectionHeader>
                            <InfoDiv>
                                Jacob's Musical Chimes<br />
                                1831 Solano Ave, Ste A<br />
                                Berkeley, CA 94707<br />
                                800-627-5840<br />
                                info@jacobschimes.com<br />
                            </InfoDiv>
                        </StyledFooterSection>
                    </StyledFooterContainer>
                </React.Fragment>
            }
        </MasterPage>
    );
}

export default Home;