import React from 'react';
import styled from 'styled-components';

/* usage example:
<div style="position: relative;">
<CloseDialogBar />
{content goes here with 32px margin-top}
</div>
*/

const StyledBar = styled.i<{ $height: number; $right: number; $color: string }>`
    position: absolute;
    right: ${props => props.$right}px;
    top: 0;
    border: 1px solid ;
    font-size: ${props => props.$height}px;
    height: ${props => props.$height}px;
    width: ${props => props.$height - 6}px;
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
    color: ${props => props.$color};
`
interface CloseDialogBarProps {
    height?: number;        // default to 24
    marginRight?: number;   // default to 0
    color?: string;         // default to red
    onClose: () => void;
}
const CloseDialogBar: React.FC<CloseDialogBarProps> = (props) => {
    return (
        <StyledBar className="fas fa-times" $height={props.height ?? 24} $right={props.marginRight ?? 2} $color={props.color ?? "red"} onClick={props.onClose} />
    )
}
export default CloseDialogBar;
