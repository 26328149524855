import React from 'react';
import styled from 'styled-components';

import SamModal from './SamModalV2';

const VideoModalContainer = styled.div`
    max-width: 600px;
`
const StyledCaption = styled.div`
    font-size: 20px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
`

interface VideoModalProps {
    youtubeId: string;          // ok to precede with video://
    caption?: string;
    modalClosed: () => void;
}
const VideoModal: React.FC<VideoModalProps> = (props) => {
    return (
        <SamModal component={VideoModalComponent as React.FC} componentProps={props} closeBarClicked={props.modalClosed} />
    )
}
const VideoModalComponent: React.FC<VideoModalProps> = (props) => {
    return (
        <VideoModalContainer>
            {props.caption && <StyledCaption dangerouslySetInnerHTML={{ __html: props.caption }} />}
            <iframe title="video" width="560" height="315" src={"https://www.youtube.com/embed/" + props.youtubeId} frameBorder="0" allowFullScreen></iframe>
        </VideoModalContainer>
    )
}
export default VideoModal;