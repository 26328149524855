export const selectStateList = [
    { caption: "", value: ""},
    { caption: "Alabama", value: "AL" },
    { caption: "Alaska", value: "AK" },
    { caption: "American Samoa", value: "AS" },
    { caption: "Arizona", value: "AZ" },
    { caption: "Arkansas", value: "AR" },
    { caption: "California", value: "CA" },
    { caption: "Colorado", value: "CO" },
    { caption: "Connecticut", value: "CT" },
    { caption: "Delaware", value: "DE" },
    { caption: "District of Columbia", value: "DC" },
    { caption: "Florida", value: "FL" },
    { caption: "Georgia", value: "GA" },
    { caption: "Guam", value: "GU" },
    { caption: "Hawaii", value: "HI" },
    { caption: "Idaho", value: "ID" },
    { caption: "Illinois", value: "IL" },
    { caption: "Indiana", value: "IN" },
    { caption: "Iowa", value: "IA" },
    { caption: "Kansas", value: "KS" },
    { caption: "Kentucky", value: "KY" },
    { caption: "Louisiana", value: "LA" },
    { caption: "Maine", value: "ME" },
    { caption: "Marshall Islands", value: "MH" },
    { caption: "Maryland", value: "MD" },
    { caption: "Massachusetts", value: "MA" },
    { caption: "Michigan", value: "MI" },
    { caption: "Minnesota", value: "MN" },
    { caption: "Mississippi", value: "MS" },
    { caption: "Missouri", value: "MO" },
    { caption: "Montana", value: "MT" },
    { caption: "Nebraska", value: "NE" },
    { caption: "Nevada", value: "NV" },
    { caption: "New Hampshire", value: "NH" },
    { caption: "New Jersey", value: "NJ" },
    { caption: "New Mexico", value: "NM" },
    { caption: "New York", value: "NY" },
    { caption: "North Carolina", value: "NC" },
    { caption: "North Dakota", value: "ND" },
    { caption: "Northern Mariana Islands", value: "MP" },
    { caption: "Ohio", value: "OH" },
    { caption: "Oklahoma", value: "OK" },
    { caption: "Oregon", value: "OR" },
    { caption: "Palau", value: "PW" },
    { caption: "Pennsylvania", value: "PA" },
    { caption: "Puerto Rico", value: "PR" },
    { caption: "Rhode Island", value: "RI" },
    { caption: "South Carolina", value: "SC" },
    { caption: "South Dakota", value: "SD" },
    { caption: "Tennessee", value: "TN" },
    { caption: "Texas", value: "TX" },
    { caption: "Utah", value: "UT" },
    { caption: "Vermont", value: "VT" },
    { caption: "Virgin Islands", value: "VI" },
    { caption: "Virginia", value: "VA" },
    { caption: "Washington", value: "WA" },
    { caption: "West Virginia", value: "WV" },
    { caption: "Wisconsin", value: "WI" },
    { caption: "Wyoming", value: "WY" },
];
